import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";

import "../submodule/src/scss/style.scss";
import { AddBarModal, AddElementModal, DetailModal } from "../modals";
import { buildTimebar } from "../builders";
import { Legend } from "../components";
// import { sortTL } from "../redux/dataSlice";
import { START_YEAR, NUM_OF_YEARS, MIN_ZOOM, MAX_ZOOM } from "../constants";
import Timeline from "../submodule/src/index.jsx";
import ListTimelinesModal from "../modals/ListTimelinesModal";
// eslint-disable-next-line no-alert

const sortTracks = (tracks) => {
  return tracks.sort((a, b) => {
    return a.order - b.order;
  });
};

const CustomTimeline = ({
  showLegend,
  shareId,
  currentScrollPos,
  timeObject,
}) => {
  // Local State
  const [open, setOpen] = useState(false);
  const [openAddBarModal, setOpenAddBarModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openElementModal, setOpenElementModal] = useState(false);
  // const [scrollToNow, setScrollToNow] = useState(false); // temp testing for false
  const [selectedTrackData, setSelectedTrackData] = useState(null);
  const [selectedTrackListModal, setSelectedTrackListModal] = useState(false);
  const [legendHeight, setLegendHeight] = useState(0);
  // Global State
  const timelineHistory = useSelector((state) => state.data.timelineHistory);
  const selectedData = useSelector((state) => state.data.selectedData);
  const sheetData = useSelector((state) => state.data.sheets);
  const zoomLevel = useSelector((state) => state.user.zoomLevel);

  let sheet = sheetData.find((sheet) => sheet.id == selectedData);
  const start = sheet.config.startDate
    ? new Date(sheet.config.startDate)
    : new Date(`${START_YEAR}`);
  const end = sheet.config.endDate
    ? new Date(sheet.config.endDate)
    : new Date(`${START_YEAR + NUM_OF_YEARS}`);

  const timebar = buildTimebar(
    start.getFullYear(),
    end.getFullYear() - start.getFullYear() + 1
  );

  let timelines = sheet.Timelines;
  let displayTracks = {};

  if (timelines) {
    for (let i = 0; i < timelines.length; i++) {
      let tl = timelines[i];
      const dynamicKey = Object.keys(tl.config)[0];
      let id = tl.id;
      let config = { ...tl.config[dynamicKey], _id: id }; // _id is fix for sorting track-id gets out of sync
      config.elements = config.elements
        ? config.elements.map((ele) => {
            let end = new Date(ele.end);
            let intensity = ele.intensity || 0;
            let start = new Date(ele.start);
            let style = JSON.parse(JSON.stringify(ele.style));
            let title = tl.name;
            return {
              ...ele,
              start,
              end,
              title,
              intensity,
              style,
              track_id: id, // TODO: Use track_id throughout codebase
            }; // track_id is fix for sorting track-id gets out of sync
          })
        : [];
      displayTracks[id] = config;
    }
  }
  let tracks = sortTracks(Object.values(displayTracks));

  const addElementToTrack = (_id) => {
    console.log({ _id })
    let timeline = timelines.find((tl) => tl.id === _id);
    setSelectedTrackData({ timeline, elementId: 0 });
    setOpenElementModal(true);
  };
  const clickElement = (element) => {
    let timeline = timelines.find((tl) => tl.id === element.track_id);
    let elementId = element.subtrackId;
    setSelectedTrackData({ timeline, element, elementId });
    setOpenDetailModal(true);
  };


  // remove?
  // setTimeout(function () {
  //   window.scrollTo(0, 0);
  // }, 1000);

  useEffect(() => {
    // Bar Labelling
    Array.from(document.querySelectorAll(".rt-element__content")).forEach(
      (segment) => {
        const segmentRect = segment.getBoundingClientRect();
        const child = segment.querySelector(".rt-element__title");

        if (child) {
          const childRect = child.getBoundingClientRect();

          childRect.width >= segmentRect.width
            ? child.setAttribute("style", "opacity: 0 !important;")
            : child.setAttribute("style", "opacity: 1 !important;");
        }
      }
    );

    // Legend
    setLegendHeight(document.querySelector("#legend").offsetHeight);

    // Grid
    const timelineCount =
      timelineHistory[0] && timelineHistory[0].Timelines
        ? timelineHistory[0].Timelines.length
        : 0;
    if (timelineCount) {
      document.querySelector(".rt-grid").style.height =
        60 * timelineCount + 5 + "px";
    }
  }, [tracks]);

  return (
    <>
      <div className={`timeline-wrapper ${zoomLevel >= 20 ? "day-zoom" : ""}`}>
        {tracks && (
          <Timeline
            scale={{
              start,
              end,
              zoom: zoomLevel,
              zoomMin: MIN_ZOOM,
              zoomMax: MAX_ZOOM,
            }}
            isOpen={open}
            toggleOpen={() => {
              setOpen(!open);
            }}
            clickElement={(e) => {
              clickElement(e);
            }}
            clickTrackButton={(track) => {
              // eslint-disable-next-line no-alert
              alert(JSON.stringify(track));
            }}
            timebar={timebar}
            tracks={tracks}
            addElementId={(_id) => {
              addElementToTrack(_id);
            }}
            addElement={() => {
              return shareId;
            }}
            now={new Date()}
            enableSticky
            // scrollToNow={scrollToNow}
            currentScrollPos={currentScrollPos}
            timeObject={timeObject}
          />
        )}
      </div>
      <Legend 
        {...{ setSelectedTrackListModal, showLegend, tracks: timelines }} 
      />
      <ListTimelinesModal
        {...{ selectedTrackListModal, setSelectedTrackListModal }}
      />
      {openDetailModal && (
        // this could use a prop refactor
        <DetailModal
          openModal={openDetailModal}
          setOpenModal={setOpenDetailModal}
          data={selectedTrackData.timeline}
          element={selectedTrackData.element}
          elementId={selectedTrackData.elementId}
          shareId={shareId}
        />
      )}
      {(shareId === "undefined" ? true : false) && (
        <AddBarModal
          openModal={openAddBarModal}
          setOpenModal={setOpenAddBarModal}
        />
      )}
      {openElementModal && (shareId === "undefined" ? true : false) && (
        <AddElementModal
          openModal={openElementModal}
          setOpenModal={setOpenElementModal}
          data={selectedTrackData.timeline}
          elementId={selectedTrackData.elementId}
        />
      )}
    </>
  );
};

const MemoizedCustomTimeline = memo(CustomTimeline);

export { MemoizedCustomTimeline };
