import {
    MAX_ELEMENT_GAP,
    MAX_MONTH_SPAN,
    MAX_TRACK_START_GAP,
    MIN_MONTH_SPAN,
    MONTH_NAMES,
    MONTHS_PER_QUARTER,
    MONTHS_PER_YEAR,
    NUM_OF_MONTHS,
    QUARTERS_PER_YEAR,
} from "./constants";

import {
    addMonthsToYear,
    addMonthsToYearAsDate,
    colourIsLight,
    getWeekOfMonth,
    hexToRgb,
    nextColor,
} from "./utils";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

export const buildQuarterCells = (START_YEAR, NUM_OF_YEARS) => {
    const v = [];
    for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const quarter = (i % 4) + 1;
        const startMonth = i * MONTHS_PER_QUARTER;
        const s = addMonthsToYear(START_YEAR, startMonth);
        const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER);
        v.push({
            id: `${s.year}-q${quarter}`,
            title: `Q${quarter} ${s.year}`,
            start: new Date(`${s.year}-${s.month}-01`),
            end: new Date(`${e.year}-${e.month}-01`)
        });
    }
    return v;
};

export const buildMonthCells = (START_YEAR, NUM_OF_YEARS) => {
    const v = [];
    for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const startMonth = i;
        const start = addMonthsToYearAsDate(START_YEAR, startMonth);
        const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1);
        v.push({
            id: `m${startMonth}`,
            title: MONTH_NAMES[i % 12]+` ${(START_YEAR + parseInt(i/12)).toString()}`,
            start,
            end
        });
    }
    return v;
};

export const buildWeekCells = (START_YEAR, NUM_OF_YEARS) => {
    const v = [];
    const weeks = [];

    for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        // Get months in date format
        const startMonth = i;
        const start = addMonthsToYearAsDate(START_YEAR, startMonth);
        const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1);
        // Get first and last day of the month
        const firstDay = moment(start).startOf("month");
        const endDay = moment(end).endOf("month");
        // Create a range to iterate through
        const monthRange = moment.range(firstDay, endDay);
        // Get all weeks during current month
        for (let day of monthRange.by("days")) {
            const weekOfMonthVal = getWeekOfMonth(day.toDate());
            if (weeks.length === 0 || weeks[weeks.length - 1].weekOfMonth !== weekOfMonthVal) {
                weeks.push({ day: day, weekOfMonth: weekOfMonthVal });
            }
        }
    }

    weeks.map((week, index) => {
        const weekRange = moment().range(week.day, weeks[index + 1] ? weeks[index + 1].day : week.day);

        v.push({
            id: `wk${index}`,
            title: `W${week.weekOfMonth + 1}`,
            start: weekRange.start.toDate(),
            end: weekRange.end.toDate()
        });
    });

    return v;
};

export const buildDayCells = (START_YEAR, NUM_OF_YEARS) => {
    const v = [];
    const months = [];
    // make array of all the months from start year to end year
    let monthCounter = 1;
    let startYear = parseInt(START_YEAR);
    let endYear = startYear + parseInt(NUM_OF_YEARS);

    for (startYear; startYear < endYear; monthCounter++) {
        if (monthCounter === 13) {
            startYear++;
            monthCounter = 0;
        } else {
            const dateStr = monthCounter < 10 ? `${startYear}-0${monthCounter}` : `${startYear}-${monthCounter}`;

            months.push(dateStr);
        }
    }

    // iterate over array and get all days in each month
    months.map((date, index) => {
        const daysInMonth = moment(`${date}`, "YYYY-MM").daysInMonth();

        for (let index = 0; index < daysInMonth; index++) {
            let startString;

            if (index === 9) {
                startString = `${date}-${index + 1}`;
            } else if (index > 9) {
                startString = `${date}-${index + 1}`;
            } else {
                startString = `${date}-0${index + 1}`;
            }

            const startDate = moment(startString);
            const endDate = moment(startString).add(1, "day");

            v.push({
                id: `d${startString}`,
                title: startDate.format("DD"),
                start: startDate.toDate(),
                end: endDate.toDate()
            });
        }
    });

    return v;
};

export const buildTimebar = (START_YEAR, NUM_OF_YEARS) => [
    {
        id: "quarters",
        title: "Quarters",
        cells: buildQuarterCells(START_YEAR, NUM_OF_YEARS),
        style: {}
    },
    {
        id: "months",
        title: "Months",
        cells: buildMonthCells(START_YEAR, NUM_OF_YEARS),
        useAsGrid: true,
        style: {}
    },
    {
        id: "weeks",
        title: "Weeks",
        cells: buildWeekCells(START_YEAR, NUM_OF_YEARS),
        style: {}
    },
    {
        id: "days",
        title: "Days",
        cells: buildDayCells(START_YEAR, NUM_OF_YEARS),
        style: {}
    }
];

export const buildElement = ({ trackId, start, end, i }) => {
    const bgColor = nextColor();
    const color = colourIsLight(...hexToRgb(bgColor)) ? "#000000" : "#ffffff";
    return {
        id: `t-${trackId}-el-${i}`,
        //id: `track-${trackId}`,
        trackId: `track-${trackId}`,
        subtrackId: `track-${trackId}-${i}`,
        title: "",
        start,
        end,
        style: {
            backgroundColor: `#${bgColor}`,
            color,
            borderRadius: "4px",
            boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
            textTransform: "capitalize"
        }
    };
};

export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP);
export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP);

//-------------------------------------------------------------------------
//Seems like this function is not used anywhere in the app asides from the
//two functions below which are also not used anywhere in the app
//-------------------------------------------------------------------------
export const buildElements = (trackId, START_YEAR) => {
    const v = [];
    let i = 1;
    let month = buildTrackStartGap();

    while (month < NUM_OF_MONTHS) {
        let monthSpan = Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) + MIN_MONTH_SPAN;

        if (month + monthSpan > NUM_OF_MONTHS) {
            monthSpan = NUM_OF_MONTHS - month;
        }

        const start = addMonthsToYearAsDate(START_YEAR, month);
        const end = addMonthsToYearAsDate(START_YEAR, month + monthSpan);
        v.push(
            buildElement({
                trackId,
                start,
                end,
                i
            })
        );
        const gap = buildElementGap();
        month += monthSpan + gap;
        i += 1;
    }

    return v;
};

//-------------------------------------------------------------------------
//Seems like this function is not used anywhere in the app
//-------------------------------------------------------------------------
export const buildSubtrack = (trackId, subtrackId, START_YEAR) => ({
    id: `track-${trackId}-${subtrackId}`,
    title: `Subtrack ${subtrackId}`,
    elements: buildElements(subtrackId, START_YEAR)
});

//-------------------------------------------------------------------------
//Seems like this function is not used anywhere in the app
//-------------------------------------------------------------------------
export const buildTrack = (trackId, START_YEAR) => {
    //const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1).map(i => buildSubtrack(trackId, i + 1))
    return {
        id: `track-${trackId}`,
        title: `Track ${trackId}`,
        elements: buildElements(trackId, START_YEAR),
        // tracks,
        // hasButton: true,
        // link: 'www.google.com',
        isOpen: false
    };
};
