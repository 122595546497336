import React, { useState, useEffect } from "react";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Input, Button, Modal, Form, TextArea } from "semantic-ui-react";
import { Slider } from "react-semantic-ui-range";
import { SliderPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import "../scss/modal.scss";
import { dispatchError } from "../api";
import { updateSheetTimeline } from "./actions";
import { validateDates, validateEleRanges } from "../helper";
import { WarningModal } from ".";

// TODO: Update all names if name changes

const DetailModal = ({
  openModal,
  setOpenModal,
  data,
  element,
  elementId,
  shareId,
}) => {
  const { id, start, end, style, title, intensity, description } = element;

  const auth = useSelector((state) => state.user.userData);
  // Extra Delete Modal
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningCallback, setWarningCallback] = useState(false);
  const [warningProps, setWarningProps] = useState(false);
  //
  const [values, setValues] = useState({
    color: style && style.backgroundColor ? style.backgroundColor : null,
    description,
    end,
    id,
    intensity,
    start,
    style,
    title,
  });
  const dispatch = useDispatch();

  const token = auth.token;
  const userId = auth.user.id;

  const updateValues = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  useEffect(() => {
    if (openModal == true) {
      setValues({
        id,
        start,
        end,
        title,
        description,
        style,
        color: style && style.backgroundColor ? style.backgroundColor : null,
        intensity,
      });
    }
  }, [element, openModal]);

  const handleSubmit = () => {
    const { start, end, title, description, style, intensity } = values;
    const key = Object.keys(data.config)[0];
    const subconfig = data.config[key];
    const newElements = subconfig.elements.map((ele) => {
      if (ele.subtrackId == elementId) {
        return {
          ...ele,
          description,
          end,
          intensity,
          start,
          style,
          title,
        };
      }
      return ele;
    });

    if (!validateDates(start, end)) {
      dispatchError({ message: "Please select a valid date range." });
    } else if (!validateEleRanges(newElements)) {
      dispatchError({ message: "Overlapping date ranges are not accepted." });
    } else {
      const body = {
        config: {
          [key]: {
            ...subconfig,
            style: { ...subconfig.style, ...style },
            elements: newElements,
          },
        },
        name: title,
        userId,
      };

      dispatch(
        updateSheetTimeline({
          body,
          id: data.id,
          setOpenModal,
          sheetId: data.SheetId,
          token,
        })
      );
    }
  };

  const colorChange = (color) => {
    const newStyles = { ...style };
    newStyles.backgroundColor = color.hex;

    updateValues("style", newStyles);
  };

  // Intensity Slider
  const intensitySettings = {
    max: 10,
    min: 0,
    onChange: (value) => {
      updateValues("intensity", value);
    },
    start: intensity,
    step: 1,
  };

  return (
    <Modal
      className={shareId !== "undefined" ? "shared" : ""}
      size="tiny"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header>
        <h4>Detailed View</h4>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <div className="date-wrapper">
            <Form.Field>
              <label>Start Date</label>
              <SemanticDatepicker
                disabled={shareId !== "undefined" ? true : false}
                onChange={(event, data) => {
                  event !== undefined && updateValues("start", data.value);
                }}
                value={values.start}
              />
            </Form.Field>
            <Form.Field>
              <label>End Date</label>
              <SemanticDatepicker
                disabled={shareId !== "undefined" ? true : false}
                onChange={(event, data) => {
                  // check for single day and make so dates pass validation
                  const endValMs = values.start.getTime();
                  const startValMs = data.value.getTime();
                  let endVal = data.value;

                  if (startValMs === endValMs) {
                    endVal = new Date(endValMs + 3.4 * 3600 * 1000);
                  }

                  event !== undefined && updateValues("end", endVal);
                }}
                value={values.end}
              />
            </Form.Field>
          </div>
          <Form.Field>
            <label>Intensity</label>
            <Slider
              color=""
              disabled={shareId !== "undefined" ? true : false}
              discrete
              settings={intensitySettings}
              value={values.intensity}
            />
            <div className="intensity-marker">
              <span>0</span>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10</span>
            </div>
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              disabled={shareId !== "undefined" ? true : false}
              onChange={(e) => {
                updateValues("description", e.target.value);
              }}
              placeholder="Description Notes"
              value={values.description}
            />
          </Form.Field>
          <h3>Row Details</h3>
          <Form.Field>
            <label>Title</label>
            <Input
              disabled={shareId !== "undefined" ? true : false}
              placeholder="Bar Title"
              onChange={(e) => {
                updateValues("title", e.target.value);
              }}
              value={values.title}
            />
          </Form.Field>
          <Form.Field>
            <label>Color </label>
            <div className="slider-wrap">
              {shareId === "undefined" && (
                <SliderPicker
                  color={values.style.backgroundColor}
                  onChangeComplete={colorChange}
                />
              )}
              <div
                className="slider-swatch"
                style={{ backgroundColor: values.style.backgroundColor }}
              ></div>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {shareId === "undefined" ? (
          <>
            <span style={{ float: "left" }}>
              <Button
                content="Delete Entire Row"
                icon="warning sign"
                negative
                onClick={() => {
                  setShowWarningModal(true);
                  // setWarningCallback(() => dispatch(handleDeleteRow({ auth, data, setOpenModal })));
                  setWarningCallback("handleDeleteRow");
                  setWarningProps({ auth, data, setOpenModal });
                }}
              />
              <Button
                content="Delete Segment"
                icon="calendar times"
                negative
                onClick={() => {
                  setShowWarningModal(true);
                  // setWarningCallback(() => dispatch(handleDeleteElement({ data, elementId, setOpenModal, title, token, userId })));
                  setWarningCallback("handleDeleteElement");
                  setWarningProps({
                    data,
                    elementId,
                    setOpenModal,
                    title,
                    token,
                    userId,
                  });
                }}
              />
              <Button
                content="Close"
                icon="cancel"
                onClick={() => {
                  setOpenModal(false);
                }}
                positive
              />
            </span>
            <Button
              content="Update"
              icon="calendar check"
              onClick={() => {
                handleSubmit();
              }}
              primary
            />
          </>
        ) : (
          <Button
            content="Close"
            icon="cancel"
            onClick={() => {
              setOpenModal(false);
            }}
            positive
          />
        )}
      </Modal.Actions>
      <WarningModal
        callback={warningCallback}
        callbackProps={warningProps}
        openModal={showWarningModal}
        setOpenModal={setShowWarningModal}
      />
    </Modal>
  );
};

export { DetailModal };
