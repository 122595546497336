import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  Popup,
  Segment,
} from "semantic-ui-react";
import { debounce } from "lodash";
import { navigate } from "@reach/router";

import { AccountCustomizer } from "..";
import { AddBarModal, ArrangeRowModal, EditSheetModal } from "../../modals";
import "../../scss/navigation.scss";
import rightArrow from "../../assets/icons/navigation/right-arrow.svg";
import logo from "../../assets/Chronowell_Logo-White.png";
import {
  MAX_ZOOM,
  MONTH_MS,
  NUM_OF_YEARS,
  QUARTER_MS,
  START_YEAR,
  WEEK_MS,
  YEAR_MS,
} from "../../constants";
import { setZoomLevel, logoutUser } from "../../redux/userSlice";
import {
  addSheetHistory,
  goBackSheetHistory,
  setSelectedData,
  updateSheetTimeSpan,
} from "../../redux/dataSlice";
import { getLastDayOfQuarter, getFirstDayOfQuarter } from "../../utils";
import { renderSheetTitle, zoomOptions } from "./utils";
import { postShareKeyLink, postUpdateUser, putSheet } from "./actions";
import { clearSuccessMessage, setSuccessMessage } from "../../redux/errorSlice";

const Navigation = ({
  showLegend,
  setShowLegend,
  shareId,
  timelineWidth,
  scrollPos,
}) => {
  const zoomLevel = useSelector((state) => state.user.zoomLevel);
  const userName = useSelector((state) => state.user.userData.user.name);
  const sheetTitleNumber = useSelector(
    (state) => state.data.selectedSheetTitle
  );
  const sheetData = useSelector((state) => state.data.sheets);
  const selectedSheetId = useSelector((state) => state.data.selectedData);
  const timelineHistory = useSelector((state) => state.data.timelineHistory);
  const userData = useSelector((state) => state.user.userData);

  const [endDate, setEndDate] = useState(null);
  const [mobile, setMobile] = useState(window.innerWidth < 900);
  const [openArrangeRows, setOpenArrangeRows] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSheetModal, setOpenSheetModal] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [showTimelineArrows, setShowTimelineArrows] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [timespanValue, setTimespanValue] = useState(null);

  const dispatch = useDispatch();

  const { token } = userData;
  const userId = userData.user.id;

  const startChange = (event, data) => setStartDate(data.value);
  const endChange = (event, data) => setEndDate(data.value);
  const scrollListener = () => {
    setScroll(window.scrollY);
  };

  // useEffect(() => {
  //   const elem = document.getElementById("faux-scroll-wrap");
  //   const displayTimelineArrows = () => {
  //     const scrollLeftMax = elem.scrollWidth - elem.clientWidth;
  //     if (scrollLeftMax - elem.scrollLeft <= 275) {
  //       setShowTimelineArrows(1);
  //     } else if (elem.scrollLeft <= 275 && elem.scrollLeft > 0) {
  //       setShowTimelineArrows(-1);
  //     } else {
  //       setShowTimelineArrows(0);
  //     }
  //   };
  //   elem.addEventListener("scroll", debounce(displayTimelineArrows, 1000));

  //   return () =>
  //     elem.removeEventListener("scroll", debounce(displayTimelineArrows, 1000));
  // }, []);

  useEffect(() => {
    if (timelineHistory && timelineHistory.length > 0) {
      setTimespanValue(timelineHistory[0].label);
    }
  }, [timelineHistory]);

  useEffect(() => {
    // Populates data from beginning on initial load - covers edge cases
    if (selectedSheetId && selectedSheetId !== null) {
      const selectedSheet = sheetData.filter((sheet, index) => {
        if (sheet.id === selectedSheetId) {
          return sheet;
        }
      })[0];

      let start = selectedSheet.config.startDate
        ? new Date(selectedSheet.config.startDate).getTime()
        : new Date(`${START_YEAR}`).getTime();

      let end = selectedSheet.config.endDate
        ? new Date(selectedSheet.config.endDate).getTime()
        : new Date(`${START_YEAR + NUM_OF_YEARS}`).getTime();

      editSheet(start, end);
    }
  }, [selectedSheetId]);

  // TODO: this should get refactored
  const handleSelectDefinedRange = (e, { value }) => {
    let firstDay;
    let lastDay;
    let date = new Date();
    setTimespanValue(value);

    if (value === "year") {
      firstDay = new Date(date.getFullYear(), 0, 1);
      lastDay = new Date(date.getFullYear(), 11, 31);
      editSheet(firstDay, lastDay, value);
      return;
    }

    if (value === "month") {
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      editSheet(firstDay, lastDay, value);
      return;
    }

    if (value === "week") {
      let curr = new Date();
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week
      var last = first + 7; // last day is the first day + 6 (Fixed to 7)
      firstDay = new Date(curr.setDate(first)).toUTCString();
      lastDay = new Date(curr.setDate(curr.getDate() + 7)).toUTCString();
      editSheet(firstDay, lastDay, value);
      return;
    }

    if (value === "quarter") {
      firstDay = getFirstDayOfQuarter(date);
      lastDay = getLastDayOfQuarter(date);
      editSheet(firstDay, lastDay, value);
      return;
    }

    if (value === "beginning") {
      // get current sheet
      const selectedSheet = sheetData.filter((sheet, index) => {
        if (sheet.id === selectedSheetId) {
          return sheet;
        }
      })[0];

      let start = selectedSheet.config.startDate
        ? new Date(selectedSheet.config.startDate).getTime()
        : new Date(`${START_YEAR}`).getTime();

      let end = selectedSheet.config.endDate
        ? new Date(selectedSheet.config.endDate).getTime()
        : new Date(`${START_YEAR + NUM_OF_YEARS}`).getTime();

      if (selectedSheet.Timelines !== undefined) {
        selectedSheet.Timelines.forEach((timeline, index) => {
          if (timeline.config[`track-${index + 1}`] !== undefined) {
            timeline.config[`track-${index + 1}`].elements.forEach(
              (element) => {
                if (new Date(element.start).getTime() < start) {
                  start = element.start;
                }
                if (new Date(element.end).getTime() > end) {
                  end = element.end;
                }
              }
            );
          }
        });
      } else {
        console.error("selectedSheet.Timelines undefined");
      }

      editSheet(start, end, value);
    }
  };

  const extendEndOfTimeline = (direction) => {
    const callback = (currLeftScroll) => {
      if (direction === 1) {
        document.getElementById("faux-scroll-wrap").scrollTo(currLeftScroll, 0);
      } else {
        const newOffset =
          document.getElementById("faux-scroll-wrap").clientWidth -
          currClientWidth;
        document
          .getElementById("faux-scroll-wrap")
          .scrollTo(currLeftScroll + newOffset, 0);
      }
      setShowTimelineArrows(0);
    };
    const currClientWidth =
      document.getElementById("faux-scroll-wrap").clientWidth;
    const currLeftScroll =
      document.getElementById("faux-scroll-wrap").scrollLeft;
    const selectedSheet = sheetData.filter((sheet, index) => {
      if (sheet.id === selectedSheetId) {
        return sheet;
      }
    })[0];

    const dynamicTimespan =
      new Date(selectedSheet.config.endDate).getTime() -
      new Date(selectedSheet.config.startDate).getTime();
    const currFirstDay = new Date(selectedSheet.config.startDate);
    const currLastDay = new Date(selectedSheet.config.endDate).getTime();
    let firstDay;
    let lastDay;

    // forwards (right)
    switch (direction === 1) {
      case dynamicTimespan >= YEAR_MS:
        lastDay = currLastDay + YEAR_MS;
        editSheet(
          currFirstDay,
          new Date(lastDay),
          null,
          callback(currLeftScroll)
        );
        break;
      case dynamicTimespan >= QUARTER_MS:
        lastDay = currLastDay + YEAR_MS;
        editSheet(
          currFirstDay,
          new Date(lastDay),
          null,
          callback(currLeftScroll)
        );
        break;
      case dynamicTimespan >= MONTH_MS:
        lastDay = currLastDay + QUARTER_MS;
        editSheet(
          currFirstDay,
          new Date(lastDay),
          null,
          callback(currLeftScroll)
        );
      case dynamicTimespan >= WEEK_MS:
        lastDay = currLastDay + MONTH_MS;
        editSheet(
          currFirstDay,
          new Date(lastDay),
          null,
          callback(currLeftScroll)
        );
        break;
      case dynamicTimespan > 0:
        lastDay = currLastDay + WEEK_MS;
        editSheet(
          currFirstDay,
          new Date(lastDay),
          null,
          callback(currLeftScroll)
        );
        break;
    }

    // backwards (left)
    switch (direction === -1) {
      case dynamicTimespan >= YEAR_MS:
        firstDay = currFirstDay - YEAR_MS;
        editSheet(firstDay, currLastDay, null, callback(currLeftScroll));
        break;
      case dynamicTimespan >= QUARTER_MS:
        firstDay = currFirstDay - YEAR_MS;
        editSheet(firstDay, currLastDay, null, callback(currLeftScroll));
        break;
      case dynamicTimespan >= MONTH_MS:
        firstDay = currFirstDay - QUARTER_MS;
        editSheet(firstDay, currLastDay, null, callback(currLeftScroll));
      case dynamicTimespan >= WEEK_MS:
        firstDay = currFirstDay - MONTH_MS;
        editSheet(firstDay, currLastDay, null, callback(currLeftScroll));
        break;
      case dynamicTimespan > 0:
        firstDay = currFirstDay - WEEK_MS;
        editSheet(firstDay, currLastDay, null, callback(currLeftScroll));
        break;
    }
  };

  const handleChange = useCallback(
    (e) => {
      setSearchItem(e.target.value);
    },
    [searchItem]
  );

  const handleSearchItem = () => {
    //Get all items
    const allItems = document.querySelectorAll(".rt-element__title");
    //Change Nodelist to array in order to have extra helper methods
    const _allItems = [...allItems];
    //Find item
    const foundElem = _allItems.find((item) => {
      return item.textContent.toLowerCase().includes(searchItem.toLowerCase());
    });

    if (foundElem) {
      foundElem.scrollIntoView({ behavior: "smooth", block: "center" });

      //Get the row of the current element
      foundElem.parentNode.parentNode.parentNode.parentNode.classList.add(
        "add-blue-color"
      );

      //Plus signs have a weird bug when this scroll into view functionality happens, hence we want to remove it from displaying
      const allPlus = document.querySelectorAll(".rt-track-plus");
      allPlus.forEach((plus) => {
        plus.classList.add("not-show");
      });

      setTimeout(() => {
        foundElem.parentNode.parentNode.parentNode.parentNode.classList.remove(
          "add-blue-color"
        );
        allPlus.forEach((plus) => {
          plus.classList.remove("not-show");
        });
      }, 5000);
    }
  };

  const handleOnKeyUp = (e) => {
    if (e.keyCode === 13) {
      return handleSearchItem();
    }
  };

  const editSheet = async (start, end, label = null, cb = false) => {
    // console.log("EDIT SHEET");
    // console.log({ start, end, label });
    const selectedSheet = sheetData.filter((sheet, index) => {
      if (sheet.id === selectedSheetId) {
        return sheet;
      }
    });

    if (shareId != "undefined") {
      const updatedSheet = JSON.parse(JSON.stringify(selectedSheet[0]));

      updatedSheet.config = {
        startDate: new Date(start).getTime(),
        endDate: new Date(end).getTime(),
      };

      dispatch(updateSheetTimeSpan(updatedSheet));
      // dispatch(addSheetHistory(updatedSheet)) // add if need history
      setOpenModal(false);
      setOpenSheetModal(false);
    } else {
      const newConfig = Object.assign({}, selectedSheet[0].config, {
        startDate: start,
        endDate: end,
      });

      await dispatch(
        putSheet(
          {
            userId,
            config: newConfig,
          },
          () => {
            setStartDate(null);
            setEndDate(null);
            setOpenModal(false);
            setOpenSheetModal(false);
          },
          label,
          selectedSheetId,
          token
        )
      );

      await dispatch(
        postUpdateUser(
          {
            id: userData.user.id,
            userId: userData.user.id,
            config: { bg: userData.user.config.bg, dbZoomLevel: 20 },
          },
          () => {
            setOpenModal(false);
            setOpenSheetModal(false);
            cb && cb();
          },
          token
        )
      );
    }
  };

  const headerListener = (e) => {
    editSheet(e.detail.start, e.detail.end);
    setTimespanValue(null);
  };

  const resizeListener = (e) => {
    window.innerWidth < 900 ? setMobile(true) : setMobile(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener, true);
    window.addEventListener("headerClick", headerListener, true);
    window.addEventListener("resize", resizeListener, true);
    return () => {
      window.removeEventListener("scroll", scrollListener, true);
      window.removeEventListener("headerClick", headerListener, true);
      window.removeEventListener("resize", resizeListener, true);
    };
  }, []);

  const handleSetCustomRange = () => {
    editSheet(startDate, endDate);
  };

  return (
    <Segment id="navigation" inverted className={scroll > 130 ? "sticky" : ""}>
      <div className="first-row">
        <div className="left-column">
          <div
            className="arrow-wrapper"
            onClick={() => {
              navigate("/");
            }}
          >
            <Popup
              content="Go Back to Menu"
              trigger={<Icon name="arrow left" />}
            />
          </div>
          <div className="details">
            <img id="logo" src={logo} />
          </div>
        </div>
        <div className="middle-column">
          {renderSheetTitle(sheetTitleNumber)}
        </div>
        {!shareId ? (
          <div className="right-column">
            <h4>{userName}</h4>
            <div>
              <AccountCustomizer />
              &nbsp;&nbsp;
              {window.innerWidth < 480 ? (
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("chronowellApp");
                    batch(() => {
                      dispatch(logoutUser());
                      dispatch(setSelectedData(null));
                    });
                    navigate("/");
                  }}
                  icon="cancel"
                />
              ) : (
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("chronowellApp");
                    batch(() => {
                      dispatch(logoutUser());
                      dispatch(setSelectedData(null));
                    });
                    navigate("/");
                  }}
                >
                  Logout
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="right-column">
            <div
              className="icon-wrapper"
              onClick={async () => {
                const res = await dispatch(
                  postShareKeyLink({
                    name: `Share_Link_${(1000 + Math.random() * 9000).toFixed(
                      0
                    )}`,
                    sheetId: selectedSheetId,
                    token,
                    userId,
                  })
                );
                if (res) {
                  const link = `https://chronowell.app/shared/${res}`

                  "clipboard" in navigator
                    ? await navigator.clipboard.writeText(link)
                    : document.execCommand("copy", true, link);
                  
                  dispatch(setSuccessMessage(`Copied Share Link: ${link}`))
                  setTimeout(() => {
                    dispatch(clearSuccessMessage())
                  }, 5000)
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <Icon name="share" />
              <h3>Share Timeline</h3>
            </div>
          </div>
        )}
      </div>
      <div className="second-row">
        <div className="left-column">
          <div className="input-wrapper">
            <input
              className="prompt"
              type="text"
              placeholder="Search Item"
              value={searchItem}
              onChange={handleChange}
              onKeyUp={handleOnKeyUp}
            />
            <i className="search icon" onClick={handleSearchItem}></i>
          </div>
        </div>
        <div className={`right-column`}>
          <Menu icon inverted>
            {timelineHistory.length > 1 && (
              <Menu.Item
                onClick={() => {
                  dispatch(goBackSheetHistory());
                }}
              >
                <Popup
                  content="Go Back In Timeline History"
                  trigger={<Icon name="arrow left" />}
                />
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => {
                if (window.innerWidth < timelineWidth) {
                  dispatch(setZoomLevel(zoomLevel - 1));
                }
              }}
            >
              <Popup
                content="Zoom Date Out"
                trigger={
                  <Icon
                    name="zoom-out"
                    className={
                      window.innerWidth >= timelineWidth ? "disabled" : ""
                    }
                  />
                }
              />
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                if (timespanValue !== "week") {
                  dispatch(setZoomLevel(zoomLevel + 1));
                }
              }}
            >
              <Popup
                content="Zoom Date In"
                trigger={
                  <Icon
                    name="zoom-in"
                    className={
                      zoomLevel === MAX_ZOOM || timespanValue === "week"
                        ? "disabled"
                        : ""
                    }
                  />
                }
              />
            </Menu.Item>

            {/* Set Timespan */}
            <Menu.Item>
              <Dropdown
                placeholder="Set Timespan"
                fluid
                selection
                value={timespanValue}
                options={zoomOptions}
                onChange={handleSelectDefinedRange}
                icon={<Icon name="arrows alternate horizontal" />}
                style={{
                  width: "140px",
                  fontSize: "12px",
                  border: "1px solid rgb(39, 102, 183)",
                  color: "rgb(39, 102, 183)",
                }}
              />
            </Menu.Item>

            {/* Custom Timespan */}
            {shareId === "undefined" && (
              <Menu.Item
                onClick={() => {
                  setOpenSheetModal(true);
                }}
                id="custom-timespan"
              >
                <Button
                  className={
                    scrollPos === timelineWidth - 750 ? "alert-end" : ""
                  }
                >
                  <Icon name="arrows alternate horizontal" />
                  Custom Timespan
                </Button>
              </Menu.Item>
            )}

            {/* Add Row */}
            {shareId === "undefined" && (
              <Menu.Item
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <Button>
                  <Icon name="plus" />
                  Add New Row
                </Button>
              </Menu.Item>
            )}

            {/* Arrange Rows */}
            {!mobile && shareId === "undefined" && (
              <Menu.Item
                onClick={() => {
                  setOpenArrangeRows(true);
                }}
              >
                <Button>
                  <Icon name="random" />
                  Arrange Rows
                </Button>
              </Menu.Item>
            )}

            <Menu.Item
              onClick={() => {
                setShowLegend(!showLegend);
              }}
            >
              {mobile ? (
                <Popup
                  content="Display Timeline Legend"
                  trigger={<Icon name="info circle" />}
                />
              ) : (
                <Button>
                  <Icon name="info circle" />
                  Display Legend
                </Button>
              )}
            </Menu.Item>
          </Menu>
        </div>
      </div>
      {showTimelineArrows === -1 && (
        <img
          alt="Extend Timeline Left Button"
          className="timeline-arrow"
          id="left-timeline-arrow"
          onClick={() => {
            extendEndOfTimeline(-1);
          }}
          src={rightArrow}
        />
      )}
      {showTimelineArrows === 1 && (
        <img
          alt="Extend Timeline Right Button"
          className="timeline-arrow"
          id="right-timeline-arrow"
          onClick={() => {
            extendEndOfTimeline(1);
          }}
          src={rightArrow}
        />
      )}
      {shareId === "undefined" && (
        <>
          <AddBarModal openModal={openModal} setOpenModal={setOpenModal} />
          <EditSheetModal
            openModal={openSheetModal}
            setOpenModal={setOpenSheetModal}
            editSheet={handleSetCustomRange}
            startDate={startDate}
            endDate={endDate}
            startChange={startChange}
            endChange={endChange}
          />
        </>
      )}
      {!mobile && openArrangeRows && (
        <ArrangeRowModal {...{ openArrangeRows, setOpenArrangeRows }} />
      )}
    </Segment>
  );
};

export { Navigation };
