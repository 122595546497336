/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BasicElement from "../../Elements/Basic";

//Packages from main repo
import { useDispatch, useSelector } from "react-redux";
import { putReq } from "../../../../../api";
import { updateTimeline } from "../../../../../redux/dataSlice";
import {
  setErrorMessage,
  clearErrorMessage,
} from "../../../../../redux/errorSlice";
import { useEffect, useRef, memo, useState } from "react";

const Element = (props) => {
  const {
    classes,
    clickElement,
    dataSet,
    description,
    end,
    id,
    intensity,
    mainTitle,
    start,
    style,
    subtrackId,
    time,
    title,
    tooltip,
    trackId,
  } = props;

  const addOneDay = (date) => {
    let newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    return newDate;
  };
  const handleClick = useCallback(
    (e) => {
      clickElement(props);
    },
    [props]
  );
  const elementStyle = {
    ...time.toStyleLeftAndWidth(start, addOneDay(end)),
    ...(clickElement ? { cursor: "pointer" } : {}),
  };

  const trackElemRef = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.userData);
  const [offSetLeft, setOffSetLeft] = useState();
  const token = auth.token;
  const userId = auth.user.id;

  // ----------------------------------------------------------------------------
  // Get SheetId and a certain id config
  // ----------------------------------------------------------------------------
  const selectedData = useSelector((state) => state.data.selectedData);
  const sheetData = useSelector((state) => state.data.sheets);
  let sheet = sheetData.find((sheet) => sheet.id == selectedData);
  let timelines = sheet.Timelines;
  let timeline = timelines.find((tl) => tl.config[trackId]);

  //----------------------------------------------------------------------------
  //Functionality to prevent dragging over
  //----------------------------------------------------------------------------

  const checkTrack = (pos) => {
    //Get all elements in this row
    //Create a new array that doesnt have currently dragged item
    const newArray = timeline.config[trackId]?.elements.filter((elem) => {
      // console.log(elem.title, title);
      return elem.title !== title;
    });

    //create a new array containing only thier dates
    const dateArray = newArray.map((elem) => {
      const startDistance = time.toX(new Date(elem.start));
      const endDistance = time.toX(new Date(elem.end));
      return [startDistance, endDistance];
    });

    //check if current position is in between any of the elements position
    const isPresent = dateArray.some((elem) => {
      return pos >= elem[0] && pos <= elem[1];
    });

    // console.log(isPresent, "IS PRESENT");
    return isPresent;
  };

  //----------------------------------------------------------------------------
  //Drag and drop implementation(Start)
  //----------------------------------------------------------------------------

  let elementWidth; // Width of element to drag
  window.initialMousePosToDoc = undefined;
  let isDragging = false; // Make sure mousedown event occurs before any logic in mousemove event can occur
  let isDraggingLeft = false;
  let isDraggingRight = false;
  let newWidth;
  let oldLeftPos;
  let newLeftPos;
  let draggingFromRight = true;
  let newStartDate;
  let newEndDate;

  const clamp = (value, min) => {
    return Math.max(value, min);
  };

  const handleOnTouchMove = (e) => {
    if (isDragging) {
      let difference;

      if (!draggingFromRight) {
        difference = window.initialMousePosToDoc - e.targetTouches[0].clientX;
        isDraggingLeft = true;
        newWidth = clamp(elementWidth + difference, 0);

        if (newWidth < 16) return;

        newLeftPos = oldLeftPos - difference;
        const isOver = checkTrack(newLeftPos - 6);
        if (isOver) return;

        trackElemRef.current.style.width = `${newWidth}px`;
        trackElemRef.current.style.left = `${newLeftPos}px`;

        newStartDate =
          time.fromX(newLeftPos) !== undefined
            ? time.fromX(newLeftPos)
            : newStartDate;
        newEndDate = time.fromX(newLeftPos + newWidth - 4);
      } else if (draggingFromRight) {
        difference = e.targetTouches[0].clientX - window.initialMousePosToDoc;
        isDraggingRight = true;
        newWidth = clamp(elementWidth + difference, 0);

        if (newWidth < 16) return;
        const isOver = checkTrack(newLeftPos + newWidth + 3);
        if (isOver) return;

        trackElemRef.current.style.width = `${newWidth}px`;

        newLeftPos = oldLeftPos;
        newStartDate =
          time.fromX(newLeftPos) !== undefined
            ? time.fromX(newLeftPos)
            : newStartDate;
        newEndDate = time.fromX(newLeftPos + newWidth - 4);
      }
    }
  };

  const handleOnMouseMove = (e) => {
    if (isDragging) {
      let difference;

      if (!draggingFromRight) {
        difference = window.initialMousePosToDoc - e.clientX;
        isDraggingLeft = true;
        newWidth = clamp(elementWidth + difference, 0);

        if (newWidth < 16) return;

        newLeftPos = oldLeftPos - difference;

        trackElemRef.current.style.width = `${newWidth}px`;
        trackElemRef.current.style.left = `${newLeftPos}px`;

        newStartDate =
          time.fromX(newLeftPos) !== undefined
            ? time.fromX(newLeftPos)
            : newStartDate;
        newEndDate = time.fromX(newLeftPos + newWidth - 4);
      } else if (draggingFromRight) {
        difference = e.clientX - window.initialMousePosToDoc;
        isDraggingRight = true;
        newWidth = clamp(elementWidth + difference, 0);

        if (newWidth < 16) return;

        trackElemRef.current.style.width = `${newWidth}px`;

        newLeftPos = oldLeftPos;
        newStartDate =
          time.fromX(newLeftPos) !== undefined
            ? time.fromX(newLeftPos)
            : newStartDate;
        newEndDate = time.fromX(newLeftPos + newWidth - 4);
      }
    }
  };

  const handleOnTouchStart = (e) => {
    const { target, targetTouches } = e;
    const { dataset } = target;
    const { clientX } = targetTouches[0]; //clientX = mouse position from left of screen
    const { col } = dataset;

    elementWidth = parseInt(trackElemRef.current.style.width);
    oldLeftPos = parseInt(trackElemRef.current.style.left);

    if (col === "1") {
      window.initialMousePosToDoc = clientX;
      isDragging = true;
      draggingFromRight = false;
    } else if (col === "2") {
      window.initialMousePosToDoc = clientX;
      isDragging = true;
    }
  };

  const handleOnMouseDown = (e) => {
    const { target, nativeEvent } = e;
    const { dataset } = target;
    const { clientX } = nativeEvent; //clientX = mouse position from left of screen
    const { col } = dataset;

    elementWidth = parseInt(trackElemRef.current.style.width);
    oldLeftPos = parseInt(trackElemRef.current.style.left);

    if (col === "1") {
      window.initialMousePosToDoc = clientX;
      isDragging = true;
      draggingFromRight = false;
    } else if (col === "2") {
      window.initialMousePosToDoc = clientX;
      isDragging = true;
    }
  };

  const handleOnMouseUp = (e) => {
    const nStart = moment(newStartDate).unix();
    const prevDay1 = moment(newStartDate).startOf("day").unix();
    const endDay1 = moment(newStartDate).endOf("day").unix();
    const nEnd = moment(newEndDate).unix();
    const prevDay2 = moment(newEndDate).startOf("day").unix();
    const endDay2 = moment(newEndDate).endOf("day").unix();

    // rounding logic for snapping to days
    const startOfStart =
      Math.abs(nStart - prevDay1) < Math.abs(nStart - endDay1)
        ? moment(newStartDate).startOf("day")
        : moment(newStartDate).add(1, "days").startOf("day");
    const startOfEnd =
      Math.abs(nEnd - prevDay2) < Math.abs(nEnd - endDay2)
        ? moment(newEndDate).startOf("day")
        : moment(newEndDate).add(1, "days").startOf("day");

    if (isDragging) {
      // if dates are the same do not update - reset (remove timestamps)
      if (
        (isDraggingLeft &&
          new Date(start.toDateString()).getTime() ===
            new Date(startOfStart).getTime()) ||
        (isDraggingRight &&
          new Date(end.toDateString()).getTime() ===
            new Date(startOfEnd).getTime())
      ) {
        trackElemRef.current.style.width = `${elementWidth}px`;
        trackElemRef.current.style.left = `${oldLeftPos}px`;
        isDragging = false;
        isDraggingLeft = false;
        isDraggingRight = false;
        return;
      }

      let key = Object.keys(timeline.config)[0];
      let subconfig = timeline.config[key];
      const newElements = subconfig.elements.map((ele) => {
        if (ele.subtrackId == subtrackId) {
          return {
            start: isDraggingLeft ? startOfStart.toISOString() : start,
            end: isDraggingRight ? startOfEnd.toISOString() : end,
            title,
            description,
            intensity,
            style,
            id,
            subtrackId,
            trackId,
          };
        }
        return ele;
      });

      const body = {
        userId,
        name: title,
        config: {
          [trackId]: {
            description,
            id: trackId,
            style,
            elements: newElements,
            title: mainTitle,
          },
        },
      };

      putReq(
        `/api/sheet/${timeline.SheetId}/timeline/${timeline.id}`,
        body,
        token
      )
        .then((res) => {
          dispatch(updateTimeline(res.data.timeline));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
          setTimeout(() => {
            dispatch(clearErrorMessage());
          }, 5000);
        });
    }

    isDragging = false;
    isDraggingLeft = false;
    isDraggingRight = false;
  };

  useEffect(() => {
    // Touch Events
    document.addEventListener("touchmove", handleOnTouchMove);
    document.addEventListener("touchend", handleOnMouseUp);
    // Mouse Events
    document.addEventListener("mousemove", handleOnMouseMove);
    document.addEventListener("mouseup", handleOnMouseUp);
    return () => {
      // Touch Events
      document.removeEventListener("touchmove", handleOnMouseMove);
      document.removeEventListener("touchend", handleOnMouseUp);
      // Mouse Events
      document.removeEventListener("mousemove", handleOnMouseMove);
      document.removeEventListener("mouseup", handleOnMouseUp);
    };
  });

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setOffSetLeft(20);
    } else {
      setOffSetLeft(100);
    }
  }, []);

  return (
    <div className="rt-track__element" style={elementStyle} ref={trackElemRef}>
      <div
        className="left-drag"
        data-col="1"
        onMouseDown={handleOnMouseDown}
        onTouchStart={handleOnTouchStart}
      ></div>
      <BasicElement
        title={title}
        start={start}
        end={end}
        style={style}
        classes={classes}
        dataSet={dataSet}
        tooltip={tooltip}
        intensity={intensity}
        description={description}
        handleOnClick={handleClick}
      />
      <div
        className="right-drag"
        data-col="2"
        onMouseDown={handleOnMouseDown}
        onTouchStart={handleOnTouchStart}
      ></div>
    </div>
  );
};

Element.propTypes = {
  time: PropTypes.shape({
    toStyleLeftAndWidth: PropTypes.func,
  }).isRequired,
  style: PropTypes.shape({}),
  classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  dataSet: PropTypes.shape({}),
  title: PropTypes.string,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  tooltip: PropTypes.string,
  clickElement: PropTypes.func,
  intensity: PropTypes.number,
};

Element.defaultTypes = {
  clickElement: undefined,
};

export default memo(Element);
