import React, { useState, useEffect } from "react";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Button, Modal, Form, TextArea } from "semantic-ui-react";
import { Slider } from "react-semantic-ui-range";
import { useDispatch, useSelector } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import "../scss/modal.scss";
import { dispatchError } from "../api";
import { setEndDateUtil } from "../utils";
import { updateSheetTimeline } from "./actions/index";
import { validateDates, validateEleRanges } from "../helper";
import { WarningModal } from "./WarningModal";

console.log("TEST")

const AddElementModal = ({ openModal, setOpenModal, data, elementId }) => {
  console.log("AddElementModal")
  console.dir({ data })
  console.dir({ elementId })
  let key = Object.keys(data.config)[0];
  let config = data.config[key];
  let element;
  if (config.elements) {
    element = config.elements.find((ele) => ele.subtrackId === elementId);
  }
  if (elementId === 0) {
    element = {
      styles: {},
    };
  }

  const auth = useSelector((state) => state.user.userData);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [values, setValues] = useState({
    color:
      config.style && config.style.backgroundColor
        ? config.style.backgroundColor
        : null,
    description: "",
    end: null,
    id: elementId,
    intensity: 0,
    start: null,
    style: config.style ? config.style : {},
    title: data.name ? data.name : "",
  });
  const dispatch = useDispatch();

  const userId = auth.user.id;
  const token = auth.token;

  const updateValues = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  const handleSubmit = () => {
    let { start, end, title, description, style, intensity } = values;
    let key = Object.keys(data.config)[0];
    let subconfig = data.config[key];
    let trackNum = key.split("-")[1];
    let eleNum = subconfig.elements ? subconfig.elements.length + 1 : 0;
    const newElements = subconfig.elements.concat({
      id: `t-1-el-${eleNum}`,
      trackId: key,
      subtrackId: `track-${trackNum}-${eleNum}`,
      title,
      description,
      intensity,
      start,
      end,
      style,
    });

    // if (!validateDates(values.start, values.end)) {
    //   dispatchError({ message: "Please select a valid date range." });
    // } else if (!validateEleRanges(newElements)) {
    //   dispatchError({ message: "Overlapping date ranges are not accepted." });
    // } else {
      const body = {
        config: {
          [key]: {
            ...subconfig,
            style: { ...subconfig.style, ...style },
            elements: newElements,
          },
        },
        description,
        name: title,
        userId,
      };

      console.dir(body)

      // dispatch(
      //   updateSheetTimeline({
      //     body,
      //     id: data.id,
      //     setOpenModal,
      //     sheetId: data.SheetId,
      //     token,
      //   })
      // );
    // }
  };

  // Intensity Slider
  const intensitySettings = {
    start: 0,
    min: 0,
    max: 10,
    step: 1,
    onChange: (value) => {
      updateValues("intensity", value);
    },
  };

  useEffect(() => {
    if (openModal === true) {
      setValues({
        color:
          config.style && config.style.backgroundColor
            ? config.style.backgroundColor
            : null,
        description: "",
        end: null,
        id: elementId,
        intensity: 0,
        start: null,
        style: config.style ? config.style : {},
        title: data.name ? data.name : "",
      });
      console.log(values)
    }
  }, [config.style, data.name, elementId, openModal]);

  return (
    <>
      <Modal
        size="tiny"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Modal.Header>
          <h4>Add Section</h4>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <div className="date-wrapper">
              <Form.Field>
                <label>Start Date</label>
                <SemanticDatepicker
                  onChange={(event, data) => {
                    event !== undefined && updateValues("start", data.value);
                  }}
                  value={values.start == null ? null : new Date(values.start)}
                />
              </Form.Field>
              <Form.Field>
                <label>End Date</label>
                <SemanticDatepicker
                  onChange={(event, data) => {
                    setEndDateUtil({
                      cb: (endValParam) => updateValues("end", endValParam),
                      data,
                      event,
                      start: values.start,
                    });
                    // check for single day and make so dates pass validation
                    // const startValMs = data.value.getTime();
                    // const endValMs = values.start.getTime();

                    // let endVal = data.value;

                    // if (startValMs === endValMs) {
                    //   endVal = new Date(endValMs + 3.4 * 3600 * 1000);
                    // }

                    // event !== undefined && updateValues("end", endVal);
                  }}
                  value={values.end == null ? null : new Date(values.end)}
                />
              </Form.Field>
            </div>
            <Form.Field>
              <label>Title</label>
              <Form.Input
                placeholder="Bar Title"
                value={values.title}
                onChange={(e) => {
                  updateValues("title", e.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Intensity</label>
              <Slider
                discrete
                value={values.intensity}
                color=""
                settings={intensitySettings}
              />
              <div className="intensity-marker">
                <span>0</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
                <span>10</span>
              </div>
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea
                placeholder="Description Notes"
                value={values.description}
                onChange={(e) => {
                  updateValues("description", e.target.value);
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            icon="warning sign"
            onClick={() => setOpenWarningModal(true)}
            content="Delete Entire Row"
          />
          <Button
            icon="cancel"
            onClick={() => {
              setOpenModal(false);
            }}
            content="Close"
          />
          <Button
            onClick={() => {
              handleSubmit();
            }}
            icon="calendar check"
            content="Submit"
            primary
          />
        </Modal.Actions>
      </Modal>
      <WarningModal
        callback="handleDeleteRow"
        callbackProps={{ auth, data, setOpenModal }}
        openModal={openWarningModal}
        setOpenModal={setOpenWarningModal}
      />
    </>
  );
};

export { AddElementModal };
