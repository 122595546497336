import React, { memo } from "react";
import PropTypes from "prop-types";

import Tracks from ".";
import Element from "./Element";

const Track = ({
  _id,
  addElement,
  addElementId,
  clickElement,
  elements,
  idx,
  isOpen,
  time,
  title,
  trackId,
  tracks,
  style,
}) => (
  <div className="tr-track">
    <div className="rt-track__elements">
      {addElement() === "undefined" && (
        <div
          className="rt-track-plus"
          name="plus square"
          onClick={() => {
            addElementId(_id);
          }}
        >
          <div className="rt-track-plus-inner">
            <span style={{ "backgroundColor": style?.backgroundColor }}>
              {idx + 1}
            </span>
            <span>+</span>
          </div>
        </div>
      )}
      {elements
        .filter(({ start, end }) => end > start)
        .map((element) => (
          <Element
            key={element.id}
            time={time}
            clickElement={clickElement}
            {...element}
            mainTitle={title}
          />
        ))}
    </div>
    {isOpen && tracks && tracks.length > 0 && (
      <Tracks time={time} tracks={tracks} clickElement={clickElement} />
    )}
  </div>
);

Track.propTypes = {
  addElement: PropTypes.func,
  addElementId: PropTypes.func,
  clickElement: PropTypes.func,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool,
  time: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  trackId: PropTypes.string,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
};

Track.defaultProps = {
  clickElement: undefined,
};

export default memo(Track);
