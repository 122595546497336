import React, { useEffect, useState } from "react";
import { Segment, Icon } from "semantic-ui-react";

import "../scss/legend.scss";

const LegendKey = ({ color, idx, onClick, title }) => (
  <div
    className="key-wrap item"
    key={idx}
    onClick={onClick}
    style={{ margin: "0 auto !important" }}
  >
    <div
      style={{
        backgroundColor: color,
      }}
      className="circle"
    >
      {++idx}
    </div>
    <h5 className="tag">{title}</h5>
  </div>
);

const Legend = ({ setSelectedTrackListModal, showLegend, tracks }) => {
  const [removePadding, setRemovePadding] = useState(false);

  let display = tracks
    ? tracks.map((track, idx) => {
        let key = Object.keys(track.config)[0];
        let config = track.config[key];

        return (
          <>
            {track &&
              config &&
              config.style &&
              config.style.backgroundColor && (
                <LegendKey
                  color={config.style.backgroundColor}
                  idx={idx}
                  key={idx}
                  onClick={() => {
                    setSelectedTrackListModal(
                      JSON.parse(JSON.stringify(config.elements))
                    );
                  }}
                  title={track.name}
                />
              )}
          </>
        );
      })
    : [];

  useEffect(() => {
    display.length === 0 ? setRemovePadding(true) : setRemovePadding(false);
  }, [display]);

  useEffect(() => {
    const desktopExtra = 70;
    let legendHeight = 0;
    if (showLegend) {
      legendHeight = document.getElementById('legend').clientHeight
    } 
    document.querySelector('.rt-timeline__body').style = `max-height: calc(100vh - ${160+legendHeight+desktopExtra}px);`
  }, [showLegend])

  return (
    <Segment
      id="legend"
      className={showLegend ? "showLegend" : "hideLegend"}
      style={{
        padding: `${removePadding ? "0px" : "0px 100px 0px 100px"}`,
      }}
    >
      <div id="legend-label" className="key-wrap">
        <Icon name="info circle" style={{ fontSize: "18px" }} />
        <h3 className="tag">Legend</h3>
      </div>
      {display}
    </Segment>
  );
};

export { Legend, LegendKey };
