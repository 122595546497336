import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";

import { LegendKey } from "../components/Legend";
import { updateSheetTimelineOrder } from "./actions";

const ArrangeRowModal = ({ openArrangeRows, setOpenArrangeRows }) => {
  const auth = useSelector((state) => state.user.userData);
  const globalRows = useSelector(
    (state) => state.data.timelineHistory[0].Timelines
  );
  const [dragging, setDragging] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localRows, setLocalRows] = useState(null);
  const dispatch = useDispatch();

  const userId = auth.user.id;
  const token = auth.token;

  useEffect(() => {
    if (
      localRows === null ||
      JSON.stringify(localRows) !== JSON.stringify(globalRows)
    ) {
      const copyGlobalRows = JSON.parse(JSON.stringify(globalRows));
      setLocalRows(copyGlobalRows);
    }
  }, [globalRows]);

  const onDrag = (idx) => {
    // console.log(`dragging ${idx}`)
    setDragging(idx);
  };

  const onDragEnd = async (idx) => {
    // console.log(`dragging ${idx} to ${dragOver}`)
    if (dragOver === false || dragOver < 0) {
      setDragging(false);
      setDragOver(false);
      return false;
    }

    let rowsSlice = localRows.slice();
    // console.log(`done dragging ${idx} to ${dragOver}`)
    let t = rowsSlice.splice(idx, 1)[0];

    if (idx > dragOver) {
      rowsSlice.splice(dragOver, 0, t);
    } else if (idx < dragOver) {
      rowsSlice.splice(dragOver - 1, 0, t);
    }

    setDragging(false);
    setDragOver(false);

    const body = {
      order: {},
      userId,
    };

    for (let i = 0; i < rowsSlice.length; i++) {
      const key = rowsSlice[i].id;
      body.order[key] = i;
    }

    setLoading(true);

    await dispatch(
      updateSheetTimelineOrder({
        body,
        token,
      })
    );

    setLoading(false)
  };

  const onClose = () => {
    // Copy Rows Over
    setOpenArrangeRows(false);
  };

  return (
    <>
      <Modal id="arrangeRowsModal" size="tiny" open={openArrangeRows}>
        <Modal.Header>
          <Icon name="random" />
          <h4>Arrange Rows</h4>
          <Icon
            name="close"
            onClick={() => {
              onClose();
            }}
          />
        </Modal.Header>
        <Modal.Content>
          {localRows &&
            localRows.map((row, idx) => {
              const key = Object.keys(row.config)[0];
              const config = row.config[key];
              return (
                <>
                  {dragging !== false &&
                    dragging !== idx &&
                    dragging + 1 !== idx && (
                      <Segment
                        className={`dropzone ${
                          dragging !== false && "dragging"
                        } ${dragOver === idx && "dragOver"}`}
                        onDragEnter={(e) => {
                          setDragOver(idx);
                          e.preventDefault();
                        }}
                        onDragLeave={() => {
                          setDragOver(false);
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        key={idx}
                      />
                    )}
                  <Segment
                    draggable="true"
                    onDrag={() => {
                      onDrag(idx);
                    }}
                    onDragEnd={() => {
                      onDragEnd(idx);
                    }}
                  >
                    <LegendKey
                      color={config.style.backgroundColor}
                      title={row.name}
                      idx={idx}
                    />
                  </Segment>
                </>
              );
            })}

          {dragging !== false && dragging !== localRows.length - 1 && (
            <Segment
              className={`dropzone ${dragging !== false && "dragging"} ${
                dragOver === localRows.length && "dragOver"
              }`}
              onDragEnter={(e) => {
                setDragOver(localRows.length);
                e.preventDefault();
              }}
              onDragLeave={() => {
                setDragOver(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              key={"end"}
            />
          )}
        </Modal.Content>
      </Modal>
      {loading && (
        <Dimmer active style={{ position: "fixed", zIndex: "9999" }}>
          <Loader>Arranging</Loader>
        </Dimmer>
      )}
    </>
  );
};

export { ArrangeRowModal };
