import React from "react";
import { Container, Image, Transition } from "semantic-ui-react";
import { navigate } from "@reach/router";

import "../../scss/login.scss";
import logo from "../../assets/Chronowell_Logo-White.png";

const EmailVerified = () => {
    setTimeout(() => {
        navigate("/");
    }, 3000);

    return (
        <Container id="login-container">
            <Transition visible={true} animation={"horizontal flip"} duration={500} id="login">
                <div className="ui raised card">
                    <Image src={logo} style={{ marginBottom: "20px", backgroundColor: "transparent" }} />
                    <div style={{ textAlign: "center", padding: "15px 0px" }}>
                        <h1>Email Verified!</h1>
                        <h3>Redirecting to login...</h3>
                    </div>
                </div>
            </Transition>
        </Container>
    );
};

export { EmailVerified };
