import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import "../scss/modal.scss";
import { handleDeleteElement, handleDeleteRow } from "./actions";

const WarningModal = ({ openModal, setOpenModal, callback, callbackProps }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      size="tiny"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header>
        <h4>Are you sure?</h4>
      </Modal.Header>
      <Modal.Content>
        <h5>You are about to delete timeline data.</h5>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setOpenModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          negative
          onClick={() => {
            callback === "handleDeleteRow"
              ? dispatch(handleDeleteRow({ ...callbackProps }))
              : dispatch(handleDeleteElement({ ...callbackProps }));
            setOpenModal(false);
          }}
        >
          Yes, Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export { WarningModal };
