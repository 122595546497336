import React from "react";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Button, Modal, Form } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import "../scss/modal.scss";
import { dispatchError } from "../api";

const EditSheetModal = ({
  editSheet,
  endChange,
  endDate,
  openModal,
  setOpenModal,
  startChange,
  startDate,
}) => (
  <Modal
    onClose={() => {
      setOpenModal(false);
    }}
    open={openModal}
    size="tiny"
  >
    <Modal.Header>
      <h4>Edit Sheet Time Range</h4>
    </Modal.Header>
    <Modal.Content>
      <h5>Sheet Details</h5>
      <Form>
        <div className="date-wrapper">
          <Form.Field>
            <label>Start Date</label>
            <SemanticDatepicker onChange={startChange} />
          </Form.Field>
          <Form.Field>
            <label>End Date</label>
            <SemanticDatepicker onChange={endChange} />
          </Form.Field>
        </div>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        content="No"
        icon="cancel"
        negative
        onClick={() => {
          setOpenModal(false);
        }}
      />
      <Button
        content="Yes"
        icon="check"
        onClick={() => {
          if (startDate & endDate) {
            editSheet();
          } else {
            dispatchError({
              message: "Please enter start date and end date.",
            });
          }
        }}
        positive
      />
    </Modal.Actions>
  </Modal>
);

export { EditSheetModal };
