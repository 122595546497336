import React from "react";
import PropTypes from "prop-types";
import { getDayMonth } from "../../utils/formatDate";
import createClasses from "../../utils/classes";

const buildDataAttributes = (attributes = {}) => {
  const value = {};
  Object.keys(attributes).forEach((name) => {
    value[`data-${name}`] = attributes[name];
  });
  return value;
};

const Basic = ({
  classes,
  dataSet,
  description,
  end,
  handleOnClick,
  intensity,
  start,
  style,
  title,
  tooltip,
}) => {
  const generateSpans = () => {
    const equationNum = Math.round(
      (new Date(end).getTime() - new Date(start).getTime()) / 1500000000
    );
    const textSpanCount = equationNum < 1 ? 1 : equationNum;
    const jsxArray = [];

    for (let i = 0; i < textSpanCount; i++) {
      jsxArray.push(
        <span className="rt-element__title" key={i}>
          {title}
        </span>
      );
    }

    return jsxArray;
  };

  return (
    <div
      className={createClasses("rt-element", classes)}
      style={style}
      {...buildDataAttributes(dataSet)}
      onClick={handleOnClick}
    >
      <div className="rt-element__content" aria-hidden="true">
        {generateSpans()}
      </div>
      <div className="rt-element__intensity">
        {intensity}
        <span></span>
      </div>
      <div className="rt-element__tooltip">
        {tooltip ? (
          // eslint-disable-next-line react/no-danger
          <div
            dangerouslySetInnerHTML={{
              __html: tooltip.split("\n").join("<br>"),
            }}
          />
        ) : (
          <div>
            {description ? (
              <div>
                {title}: {description}
              </div>
            ) : (
              <div>{title}</div>
            )}
            <div>
              {/* <strong>Start</strong> {getDayMonth(start)} {start.getFullYear()} */}
              <strong>Start</strong> {JSON.stringify(start)}
            </div>
            <div>
              <strong>End</strong> {getDayMonth(end)} {end.getFullYear()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Basic.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  style: PropTypes.shape({}),
  classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  dataSet: PropTypes.shape({}),
  tooltip: PropTypes.string,
  intensity: PropTypes.number,
  handleOnClick: PropTypes.func,
  description: PropTypes.string,
};

export default Basic;
