export const validateDates = (startDate, endDate, next= ()=>{}, invalid=()=>{}) =>{
	//convert to Dates 
	let start = new Date(startDate)
	let end = new Date(endDate)
	if(start == null || end == null){
		next()
		return true
	}
	if(start < end){
		next()
		return true
	}else{
		invalid()
		return false
	}
}
export const validateEleRanges = (elements, next=()=>{}, invalid=()=>{})=>{
	for(let i = 0; i < elements.length - 1; i++){
		for(let j = i+1; j < elements.length; j++){
			let iStart = new Date(elements[i].start)
			let iEnd = new Date(elements[i].end)
			let jStart = new Date(elements[j].start)
			let jEnd = new Date(elements[j].end)
			if(iStart < jStart){
				if(iEnd > jStart){
					invalid()
					return false
				}
			}else{
				if(iStart < jEnd){
					invalid()
					return false
				}
			}
		}
	}
	next()
	return true
}