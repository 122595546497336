import { dispatchError, deleteReq, getReq, postReq, putReq, } from "../../api";
import { deleteTimeline, newTimeline, updateTimeline, updateSheetTimelines } from "../../redux/dataSlice";
import { clearSuccessMessage, setSuccessMessage } from "../../redux/errorSlice";
import { addKey, deleteKey, getKeys, updateKey } from "../../redux/userSlice";

const createRow = ({ config, match, setOpenModal, title, token, userId }) => async dispatch => {
    try {
        const res = await postReq(
            `/api/sheet/${match.id}/timeline`,
            {
                userId,
                name: title,
                description: "",
                config
            },
            token
        )

        if (res.status === 200) {
            await dispatch(newTimeline(res.data.timeline));
            setOpenModal(false);
        }
    } catch (err) {
        dispatchError(err)
    }
}

const createShareKey = ({ name, setLoading, token, userId }) => async dispatch => {
    setLoading(true)

    try {
        const res = await postReq(
            `/api/sheet/key`,
            { name, userId },
            token
        )

        if (res.status === 200) {
            dispatch(addKey(res.data));
            return res.data
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }

    setLoading(false)
}

const deleteShareKey = ({ id, setLoading, token, userId }) => async dispatch => {
    setLoading(true)

    try {
        const res = await deleteReq(
            `/api/sheet/key/${id}`,
            { userId },
            token
        )

        if (res.status === 200) {
            dispatch(deleteKey({ id }));
        } else {
            dispatchError({ message: "Unable to delete share key" })
        }
    } catch (err) {
        dispatchError(err)
    }

    setLoading(false)
}


const handleDeleteElement = ({ data, elementId, setOpenModal, title, token, userId }) => async dispatch => {
    let key = Object.keys(data.config)[0];
    let trackNum = key.split("-")[1];
    let subconfig = data.config[key];

    const newElements = subconfig.elements
        .filter((ele) => !(ele.subtrackId === elementId))
        .map((ele, i) => {
            return {
                ...ele,
                id: `t-1-el-${i + 1}`,
                subtrackId: `track-${trackNum}-${i + 1}`,
            };
        });

    const body = {
        userId,
        name: title,
        config: {
            [key]: { ...subconfig, elements: newElements },
        },
    };

    try {
        const res = await putReq(`/api/sheet/${data.SheetId}/timeline/${data.id}`, body, token)

        if (res.status === 200) {
            dispatch(updateTimeline(res.data.timeline));
            setOpenModal(false);
        } else {
            dispatchError({ message: 'Element Failed to Delete' })
        }
    } catch (err) {
        dispatchError(err)
    }
};

const handleDeleteRow = ({ auth, data, setOpenModal }) => async dispatch => {
    try {
        const res = await deleteReq(
            `/api/sheet/${data.SheetId}/timeline/${data.id}`,
            { userId: auth.user.id },
            auth.token
        )
        if (res.status === 200) {
            dispatch(
                deleteTimeline({ timelineId: data.id, sheetId: data.SheetId })
            );
            setOpenModal(false);
        } else {
            dispatchError({ message: 'Row Failed to Delete' })
        }
    } catch (err) {
        dispatchError(err)
    }
};

const fetchNewPasswordLink = ({ email, setOpenModal }) => async dispatch => {
    try {
        const res = await getReq(`/api/auth/resetPassword/${email}`)

        if (res.status === 200) {
            dispatch(setSuccessMessage('Login details sent!'));
            setTimeout(() => {
                dispatch(clearSuccessMessage());
            }, 5000);
            setOpenModal(false)
        } else {
            dispatchError({ message: res.data })
        }
    } catch (err) {
        dispatchError(err)
    }
}

const fetchSheetKeys = ({ token, userId }) => async dispatch => {
    try {
        const res = await getReq(`/api/sheet/key/index`, { userId }, token)

        if (res.status === 200) {
            dispatch(getKeys(res.data));
        } else {
            dispatchError({ message: res.data })
        }
    } catch (err) {
        dispatchError(err)
    }
}

const linkSheetKey = ({ id, setLoading, sheetId, token, userId }) => async (dispatch) => {
    setLoading(true)

    try {
        const res = await postReq(
            `/api/sheet/key/link/${id}`,
            { userId, SheetId: sheetId },
            token
        )

        if (res.status === 200) {
            dispatch(updateKey(res.data));
            return res
        } else {
            dispatchError({ message: res.data })
        }
    } catch (err) {
        dispatchError(err)
    }

    setLoading(false);
}

const unlinkSheetKey = ({ id, setLoading, sheetId, token, userId }) => async dispatch => {
    setLoading(true)

    try {
        const res = await postReq(
            `/api/sheet/key/unlink/${id}`,
            { userId, SheetId: sheetId },
            token
        )

        if (res.status === 200) {
            dispatch(updateKey(res.data));
        } else {
            dispatchError({ message: res.data })
        }
    } catch (err) {
        dispatchError(err)
    }

    setLoading(false);
}

const updateSheetTimeline = ({ body, id, setOpenModal = false, sheetId, token }) => async (dispatch) => {
    try {
        const res = await putReq(`/api/sheet/${sheetId}/timeline/${id}`, body, token)

        if (res.status === 200) {
            dispatch(updateTimeline(res.data.timeline));
            setOpenModal && setOpenModal(false)
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}

const updateSheetTimelineOrder = ({ body, token }) => async (dispatch) => {
    try {
        // NOTE: undefined for routing with conflicting paths
        const res = await putReq(`/api/sheet/undefined/timeline-order`, body, token)

        if (res.status === 200) {
            dispatch(updateSheetTimelines(res.data.timelines));
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}


export {
    createRow,
    createShareKey,
    deleteShareKey,
    handleDeleteElement,
    handleDeleteRow,
    fetchNewPasswordLink,
    fetchSheetKeys,
    linkSheetKey,
    unlinkSheetKey,
    updateSheetTimeline,
    updateSheetTimelineOrder
}