import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Image, Icon } from "semantic-ui-react";

import "../../scss/login.scss";
import "../../scss/passwordReset.scss";
import { createNewPassword } from "./actions";
import { dispatchError } from "../../api";
import authbg from "../../assets/auth.jpg";
import logo from "../../assets/Chronowell_Logo-White.png";

const PasswordReset = ({ token }) => {
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [showPassword, setShowPassword] = useState();
  const dispatch = useDispatch();

  const resetPassword = async () => {
    if (password.length === 0 || passwordConfirm.length === 0) {
      dispatchError({ message: "Please complete the form." })
    } else if (password !== passwordConfirm) {
      dispatchError({ message: "Passwords do not match, please retype them." })
    } else {
      await dispatch(createNewPassword(password, token))
    }
  };

  return (
    <div className="auth-screen">
      <div
        className="left-column"
        style={{ backgroundImage: `url(${authbg})` }}
      >
        <div className="content">
          <div className="logo-wrapper">
            <Image
              src={logo}
              style={{ marginBottom: "20px", backgroundColor: "transparent" }}
            />
          </div>
          <h1>
            Timeline Tracking Web/Mobile Application (Progressive Web
            Application) That Allows Users To Save Health Data And Share It To
            Other People.
          </h1>
        </div>
      </div>
      <div className="right-column password-reset">
        <div className="logo-wrapper">
          <Image
            src={logo}
            style={{ marginBottom: "20px", backgroundColor: "transparent" }}
          />
        </div>
        <div className="middle">
          <h1 className="title">Reset Your Password</h1>
          <Form
            onSubmit={() => {
              resetPassword();
            }}
          >
            <div className="password-wrapper">
              <input
                required
                type={showPassword ? "text" : "password"}
                label="Enter New Password"
                placeholder="Enter New Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Icon
                name={showPassword ? "eye slash" : "eye"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
            <div className="password-wrapper">
              <input
                required
                type={showPassword ? "text" : "password"}
                label="Retype New Password"
                placeholder="Retype New Password"
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                }}
              />
              <Icon
                name={showPassword ? "eye slash" : "eye"}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
            <div className="btn-wrap">
              <Button
                positive
                type="submit"
                style={{ margin: "auto", display: "block" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export { PasswordReset };
