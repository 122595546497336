import React from "react";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";

import { getLocalStorage } from "./utils";
import { setUserData } from "./redux/userSlice";
import App from "./App";

const Root = () => {
    const dispatch = useDispatch();

    const userData = getLocalStorage("chronowellApp");

    if (userData) {
        const decodedToken = jwtDecode(userData.token);
        const { exp } = decodedToken;

        if (new Date().getTime() > exp * 1000) {
            // alert("Token expired fire logout");
            // dispatch(requestLogoutContestant());
        } else {
            dispatch(setUserData(userData));
        }
    }
    
    return <App />;
};

export default Root;
