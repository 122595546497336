// Date Range in MS
export const MONTH_MS = 2628000000;
export const QUARTER_MS = 7889600000;
export const WEEK_MS = 604800000;
export const YEAR_MS = 31557600000;
// Timeline Constants
export const START_YEAR = new Date().getFullYear();
export const NUM_OF_YEARS = 2;
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const MONTHS_PER_YEAR = 12;
export const QUARTERS_PER_YEAR = 4;
export const MONTHS_PER_QUARTER = 3;
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR;
export const MAX_TRACK_START_GAP = 4;
export const MAX_ELEMENT_GAP = 8;
export const MAX_MONTH_SPAN = 8;
export const MIN_MONTH_SPAN = 2;
export const NUM_OF_TRACKS = 20;
export const MAX_NUM_OF_SUBTRACKS = 5;
export const MIN_ZOOM = 2;
export const MAX_ZOOM = 34;
// Splash Menu Constants
export const QUOTES = [
    "If you listen to your body when it whispers, you won’t have to hear it scream.",
    "Create healthy habits, not restrictions.",
    "Self love is the greatest medicine.",
    "Your life only gets better when you do. Work on yourself and the rest will follow.",
    "Believe you can and you’re halfway there.",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison.",
    "Stop hating yourself for everything you aren’t and start loving yourself for everything you already are.",
    "Embrace and love your body. It is the most amazing thing you’ll ever own.",
    "Be patient with yourself. Nothing in nature blooms all year.",
    "You are your only limit.",
    "Don’t let your mind bully your body.",
    "You are what you eat, so don’t be fast, cheap, easy or fake.",
    "The difference between who you are and who you want to be is what you do.",
    "If you don’t take care of your body, where are you going to live?",
    "It’s not about being the best. It’s about being better than you were yesterday.",
    "Don’t wait until you’ve reached your goal to be proud of yourself. Be proud of every step you take toward reaching that goal.",
    "The body achieves what the mind believes.",
    "You don’t get what you wish for. You get what you work for.",
    "If you wouldn’t say it to a friend, don’t say it to yourself.",
    "Think about what could go right.",
    "Push harder than yesterday if you want a different tomorrow.",
    "A year from now, you’ll wish you had started today.",
    "The fact that you aren’t where you want to be should be enough motivation.",
    "Strength doesn’t come from what you can do. It comes from overcoming the things you once thought you couldn’t do.",
    "Become a priority in your life."
];
