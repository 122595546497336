import { dispatchError, postReq, putReq } from "../../../api";
import { clearErrorMessage, setErrorMessage } from "../../../redux/errorSlice";
import { addKey, setUserData } from "../../../redux/userSlice";
import {
    getSheet,
    addSheetHistory,
    updateSheetTimeSpan,
} from "../../../redux/dataSlice";
import { linkSheetKey } from "../../../modals/actions";

const postShareKeyLink = ({ name, sheetId, token, userId }) => async dispatch => {
    let keyRes, linkRes;

    try {
        keyRes = await postReq(
            `/api/sheet/key`,
            { name, userId },
            token
        )

        if (keyRes.status === 200) {
            await dispatch(addKey(keyRes.data));

            linkRes = await dispatch(
                linkSheetKey({
                    id: keyRes.data.id,
                    setLoading: () => {},
                    sheetId,
                    token,
                    userId,
                })
            );

            console.log({ linkRes })

            return linkRes.data?.code
        } else {
            dispatchError(keyRes.data || linkRes.data)
            return false
        }
    } catch (err) {
        dispatchError(err)
        return false
    }
}

const postUpdateUser = (body, cb, token) => async dispatch => {
    try {
        const res = await postReq("/api/auth/updateUser", body, token);

        cb();
        dispatch(
            setUserData({
                token,
                user: res.data.user,
            })
        );
    } catch (err) {
        dispatch(setErrorMessage(err?.message ?? err?.response?.data?.error));
        setTimeout(() => {
            dispatch(clearErrorMessage());
        }, 5000);
    };
}

const putSheet = (body, cb, label, selectedSheetId, token) => async dispatch => {
    try {
        const res = await putReq(
            `/api/sheet/${selectedSheetId}`,
            body,
            token
        );

        dispatch(updateSheetTimeSpan(res.data.sheet));
        dispatch(addSheetHistory({ sheet: res.data.sheet, label }));
        dispatch(getSheet(res.data));
        cb();
    } catch (err) {
        const msg = err.message ?? err?.response?.data?.error;
        dispatch(setErrorMessage(msg));
        setTimeout(() => {
            dispatch(clearErrorMessage());
        }, 5000);
    };
}

export { postUpdateUser, postShareKeyLink, putSheet }