import { createSlice } from "@reduxjs/toolkit";

import { MIN_ZOOM, MAX_ZOOM } from "../constants";

const initialState = {
    userData: {
        user: {
            username: null,
            email: null,
            password: null,
            userId: null,
            admin: null,
            id: null,
            createdAt: null,
            updatedAt: null,
            verified: null,
            resetToken: null
        },
        token: null,
        keys: []
    },
    zoomLevel: MIN_ZOOM + 2,
};

const mySlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        // Generic Errors for AJAX
        logoutUser: (state) => {
            state.userData.user = null;
            state.userData.token = null;
            return state;
        },
        setUserData: (state, action) => {
            if (!action.payload.user.hasOwnProperty("config") || !action.payload.user.config.hasOwnProperty("bg")) {
                action.payload.user.config = { bg: 1 }
            }
            state.userData.user = action.payload.user;
            state.userData.token = action.payload.token;
            state.zoomLevel = action.payload.user.config.dbZoomLevel ?? state.zoomLevel;
            return state;
        },
        setZoomLevel: (state, action) => {
            state.zoomLevel =
                !(action.payload < MIN_ZOOM) && !(action.payload > MAX_ZOOM) ? action.payload : state.zoomLevel;
            return state;
        },
        getKeys: (state, action) => {
            state.userData.keys = action.payload;
        },
        addKey: (state, action) => {
            let newKey = action.payload;
            if (!newKey.Sheets) {
                newKey.Sheets = [];
            }
            state.userData.keys.push(newKey);
            return state;
        },
        deleteKey: (state, action) => {
            state.userData.keys = state.userData.keys.filter(k => k.id !== action.payload.id)
            return state
        },
        updateKey: (state, action) => {
            state.userData.keys = state.userData.keys.map((key) => {
                if (key.id == action.payload.id) {
                    return action.payload;
                } else {
                    return key;
                }
            });
            return state;
        },
        removeKey: (state, action) => {
            state.userData.keys = state.userData.keys.filter((key) => key.id !== action.payload.id);
            return state;
        }
    }
});

export const { setUserData, getKeys, addKey, deleteKey, updateKey, removeKey, setZoomLevel, logoutUser } = mySlice.actions;
export default mySlice.reducer;
