import React, { useState } from "react";
import { Input, Button, Modal, Form, TextArea } from "semantic-ui-react";
import { SliderPicker } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import "../scss/modal.scss";
import { createRow } from "./actions";
import { handleStickPlusIcon } from "../submodule/src/components/Timeline/Header";
import { lumaTest, setEndDateUtil } from "../utils";

const AddBarModal = ({ openModal, setOpenModal }) => {
  // Global State
  const selectedData = useSelector((state) => state.data.selectedData);
  const sheetData = useSelector((state) => state.data.sheets);
  const token = useSelector((state) => state.user.userData.token);
  const userId = useSelector((state) => state.user.userData.user.id);
  // Details
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // DatePicker
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const startChange = (event, data) => setStartDate(data.value);
  const endChange = (event, data) => setEndDate(data.value);
  // ColorPicker
  const [color, setColor] = useState({
    backgroundColor: "#66CCFF",
    hex: "#66CCFF",
    hsl: {
      h: 200,
      s: 1,
      l: 0.7,
      a: 1,
    },
    hsv: {
      h: 200,
      s: 0.6,
      v: 1,
      a: 1,
    },
    oldHue: 200,
    rgb: {
      r: 102,
      g: 204,
      b: 255,
      a: 1,
    },
    source: "hsl",
  });
  const colorChange = (color) => {
    let newStyles = { ...color };
    newStyles.backgroundColor = color.hex;

    setColor(newStyles);
  };
  //
  const dispatch = useDispatch();

  const addBar = async () => {
    const match = sheetData.find((sheet) => {
      if (selectedData === sheet.id) {
        return sheet;
      }
    });
    if (match) {
      let config;
      const tIndex =
        match.Timelines && match.Timelines.length
          ? match.Timelines.length + 1
          : 1;
      const elements = [
        {
          id: "t-1-el-1",
          trackId: `track-${tIndex}`,
          subtrackId: `track-${tIndex}-1`,
          start: startDate,
          end: endDate,
          description,
          title: title,
          style: {
            backgroundColor: color ? color.hex : "#66CCFF",
            color: lumaTest(color.hex),
            borderRadius: "4px",
            boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
            textTransform: "capitalize",
          },
        },
      ];

      if (tIndex > 1) {
        config = {
          [`track-${tIndex}`]: {
            id: `track-${tIndex}`,
            title: `Track ${tIndex}`,
            description,
            style: {
              backgroundColor: color ? color.hex : "#66CCFF",
              color: lumaTest(color.hex),
              borderRadius: "4px",
              boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
              textTransform: "capitalize",
            },
            elements,
          },
        };
      } else {
        config = {
          "track-1": {
            id: "track-1",
            title: "Track 1",
            description,
            style: {
              backgroundColor: color ? color.hex : "#66CCFF",
              color: lumaTest(color.hex),
              borderRadius: "4px",
              boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
              textTransform: "capitalize",
            },
            elements,
          },
        };
      }

      await dispatch(
        createRow({ config, match, setOpenModal, title, token, userId })
      );

      setTitle("");
      setDescription("");

      // need to trigger scroll for dynamic tabs to appear
      handleStickPlusIcon(
        document.getElementById("faux-scroll-wrap").scrollLeft
      );
    } else {
      alert("Something Went Wrong :(");
    }
  };

  return (
    <Modal
      size="tiny"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header>
        <h4>Add New Row To Timeline</h4>
      </Modal.Header>
      <Modal.Content>
        <h5>Item Details</h5>
        <Form>
          <div className="date-wrapper">
            <Form.Field>
              <label>Start Date</label>
              <SemanticDatepicker onChange={startChange} />
            </Form.Field>
            <Form.Field>
              <label>End Date</label>
              <SemanticDatepicker
                onChange={(event, data) => {
                  setEndDateUtil({
                    cb: setEndDate,
                    data,
                    event,
                    start: startDate,
                  });
                }}
              />
            </Form.Field>
          </div>
          <Form.Field>
            <label>Title</label>
            <Input
              placeholder="Bar Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder="Description Notes"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Color </label>
            <div className="slider-wrap">
              <SliderPicker color={color} onChangeComplete={colorChange} />
              <div
                className="slider-swatch"
                style={{ backgroundColor: color.backgroundColor }}
              ></div>
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            setOpenModal(false);
          }}
          icon="cancel"
          content="No"
        />
        <Button
          positive
          icon="calendar plus"
          content="Yes"
          onClick={() => {
            addBar();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export { AddBarModal };
