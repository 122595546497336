import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { Router } from "@reach/router";
import { useSelector, shallowEqual } from "react-redux";
import GitInfo from 'react-git-info/macro';
import Loadable from "react-loadable";

import "./scss/global.scss";
import { Auth, MemoizedHome, TimelineView } from "./pages";
import { Loading, Notification } from "./components";

// Code Splitting
const Changelog = Loadable({
  loader: () => import("./pages/changelog"),
  loading: Loading,
});
const EmailVerified = Loadable({
  loader: () => import("./pages/email-verified"),
  loading: Loading,
});
const PasswordReset = Loadable({
  loader: () => import("./pages/password-reset"),
  loading: Loading,
});
const PrivacyPolicy = Loadable({
  loader: () => import("./pages/privacy-policy"),
  loading: Loading,
});

// Typefaces
require("typeface-lexend-deca");

function App({ location, navigate }) {
  const userToken = useSelector(
    (state) => state.user.userData.token,
    shallowEqual
  );
  const loader = useSelector((state) => state.error.showLoader);
  const selectedData = useSelector((state) => state.data.selectedData);

  document.title = `Chronowell - ${GitInfo().commit.hash.substring(30)}`

  return (
    <div className="App">
      <Router>
        {!userToken ? <Auth default path="/" /> : <MemoizedHome default path="/" />}
        <MemoizedHome path="shared/:shareId" />
        <PasswordReset path="reset-password/:token" />
        <EmailVerified path="email-verified" />
        <PrivacyPolicy path="privacy-policy" />
        <Changelog path="changelog" />
        {selectedData && <TimelineView path="timeline/:shareId" location={location} />}
      </Router>
      <Notification />
      {loader && (
        <Segment id="table-spinner">
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
}

export default App;
