import React from "react";

import "../scss/spinner.scss";

const Spinner = () => (
    <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export { Spinner }
