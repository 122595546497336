import React from "react";
import { useSelector } from "react-redux";
import { Message, Transition } from "semantic-ui-react";

const Notification = () => {
  const errorMessage = useSelector((state) => state.error.errorMessage);
  const successMessage = useSelector((state) => state.error.successMessage);

  return (
    <Transition.Group animation={"fly left"} duration={500}>
      {errorMessage && (
        <Message
          id="errorMessage"
          icon="cancel"
          header="There was an error"
          content={errorMessage}
          wide
        />
      )}
      {successMessage && (
        <Message
          id="successMessage"
          icon="check"
          header="There was a successful action"
          content={successMessage}
          wide
        />
      )}
    </Transition.Group>
  );
};

export { Notification };
