import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const today = `d${moment().format("YYYY-MM-DD")}`;

// const Cell = ({ time, pointerDate, title, start, end, cell }) => {
const Cell = ({ time, title, start, end, cell }) => {
  const isToday = cell.id === today;
  const todayStyles = {
    ...time.toStyleLeftAndWidth(start, end),
    backgroundColor: "#ff0000",
    color: "#fff",
  };

  // const pointerStyles = {
  //   ...time.toStyleLeftAndWidth(start, end),
  //   backgroundColor: "#000099",
  //   color: "#fff",
  // };

  return (
    <div
      className={`rt-timebar__cell ${isToday ? 'today' : ''}`}
      // style={
      //   cell.id === today
      //     ? todayStyles
      //     : pointerDate !== undefined && `d${moment(pointerDate).format("YYYY-MM-DD")}` === today
      //     ? pointerStyles
      //     : {...time.toStyleLeftAndWidth(start, end)}
      // }
      style={
        isToday
          ? todayStyles
          : { ...time.toStyleLeftAndWidth(start, end) }
      }
      onClick={() => {
        const event = new CustomEvent("headerClick", {
          detail: {
            start: cell.start,
            end: cell.end,
          },
        });
        window.dispatchEvent(event);
      }}
    >
      {title}
    </div>
  );
};

Cell.propTypes = {
  time: PropTypes.shape({
    toStyleLeftAndWidth: PropTypes.func,
  }),
  pointerDate: PropTypes.instanceOf(Date),
  title: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
};

export default Cell;
