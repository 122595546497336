import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const Grid = ({ time, grid, timebar }) => {
    return (
        <div className="rt-grid">
            {timebar.map(({ id, cells }) => {
                return <Cell key={id} id={id} time={time} cells={cells} />;
            })}
        </div>
    );
};

const Cell = ({ time, cells, id }) => {
    let display = false;

    if (
        (id === "quarters" && time.zoom <= 10) ||
        id === "months" ||
        (id === "weeks" && time.zoom > 10) ||
        (id === "days" && time.zoom >= 20)
    ) {
        display = true;
    }
    return (
        <React.Fragment>
            {display && (
                <div>
                    {cells.map((cell) => {
                        const { start, end, id } = cell;
                        const startDay = moment(start).date()
                        const endDay = moment(end).date()
                        return (
                            <div className="rt-grid__cell" style={time.toStyleLeftAndWidth(start, end, startDay, endDay)} key={id}></div>
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

Grid.propTypes = {
    grid: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.instanceOf(Date).isRequired,
            end: PropTypes.instanceOf(Date).isRequired
        })
    ).isRequired,
    time: PropTypes.shape({
        toStyleLeftAndWidth: PropTypes.func
    }).isRequired,
    timebar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string
        }).isRequired
    ).isRequired
};

export default Grid;
