import { postReq } from "../../../api";
import { clearErrorMessage, setErrorMessage } from "../../../redux/errorSlice";
import { setUserData } from "../../../redux/userSlice";

const postUpdateUser = (body, token) => async dispatch => {
    try {
        const res = await postReq("/api/auth/updateUser", body, token)
        dispatch(
            setUserData({
                user: res.data.user,
                token,
            })
        );
    } catch (err) {
        dispatch(setErrorMessage(err?.message ?? err.response.data.error));
        setTimeout(() => {
            dispatch(clearErrorMessage());
        }, 5000);
    };
}

export { postUpdateUser }