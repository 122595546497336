import { navigate } from "@reach/router";
import { dispatchError, getReq, postReq } from "../../../api";
import { getSheet, getSheets, newSheet, setSelectedData } from "../../../redux/dataSlice";


const createSheet = ({ body, setSheetIsLoading, userToken }) => async (dispatch) => {
    setSheetIsLoading(true);
    try {
        const res = await postReq("/api/sheet", body, userToken)

        if (res.status === 200) {
            const { sheet } = res.data
            await dispatch(setSelectedData(sheet.id));
            await dispatch(newSheet(sheet));
            setSheetIsLoading(false);
            navigate(`/timeline/${undefined}`)
        } else {
            setSheetIsLoading(false);
            dispatchError(res.data)
        }
    } catch (err) {
        setSheetIsLoading(false);
        dispatchError(err)
    }
}

const fetchAllSheets = ({ userId, userToken }) => async (dispatch) => {
    try {
        const res = await getReq("/api/sheet", { userId }, userToken)
        
        if (res.status === 200) {
            dispatch(getSheets(res.data));
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}

const fetchSheet = ({ id, setSheetIsLoading, userId, userToken }) => async (dispatch) => {
    setSheetIsLoading(true);

    try {
        const res = await getReq(`/api/sheet/${id}`, { userId }, userToken)

        if (res.status === 200) {
            await dispatch(setSelectedData(res.data.id));
            await dispatch(getSheet(res.data));
            setSheetIsLoading(false)
            navigate(`/timeline/${undefined}`)
        } else {
            setSheetIsLoading(false);
            dispatchError(res.data)
        }
    } catch (err) {
        setSheetIsLoading(false);
        dispatchError(err)
    }
}

const fetchAllSheetShared = ({ code }) => async (dispatch) => {
    try {
        const res = await getReq("/api/sheet/shared", { code })
        console.log({ res })
        if (res.status === 200) {
            dispatch(getSheets(res.data));
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}

const fetchSheetShared = ({ id, shareId }) => async (dispatch) => {
    console.log({ id, shareId })
    try {
        const res = await getReq(`/api/sheet/${id}/shared/${shareId}`, {})

        if (res.status === 200) {
            await dispatch(setSelectedData(res.data.id));
            await dispatch(getSheet(res.data));
            navigate(`/timeline/${shareId}`);
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}

export {
    createSheet,
    fetchAllSheets,
    fetchSheet,
    fetchAllSheetShared,
    fetchSheetShared
}