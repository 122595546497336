import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Image, Checkbox, Icon } from "semantic-ui-react";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";

import "../../scss/login.scss";
import { forgotPassword, login, register } from "./actions";
import { Spinner } from "../../components";
import logo from "../../assets/Chronowell_Logo-White.png";

const Auth = () => {
  const [ageApproval, setAgeApproval] = useState();
  const [emailForgotPass, setEmailForgotPass] = useState("");
  const [emailLogin, setEmailLogin] = useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [forgotPassActive, setForgotPassActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");
  const [registerActive, setRegisterActive] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    window.removeEventListener("keypress", handleEnter);
    window.addEventListener("keypress", handleEnter);
    return () => {
      window.removeEventListener("keypress", handleEnter);
    };
  }, [loginActive]);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (loginActive && emailLogin.length > 0 && passwordLogin.length > 0) {
        dispatch(
          login({ emailLogin, passwordLogin, rememberMe, setIsLoading })
        );
      } else if (
        registerActive &&
        ageApproval &&
        username.length > 0 &&
        emailSignup.length > 0 &&
        passwordSignup.length > 0
      ) {
        dispatch(
          register({ emailSignup, passwordSignup, setIsLoading, username })
        );
      } else if (forgotPassActive && emailForgotPass.length > 0) {
        forgotPassword();
        setTimeout(function () {
          setEmailForgotPass("");
        }, 1000);
      }
    }
  };

  // FLIP UI
  const changeAuthMode = () => {
    setForgotPassActive(false);
    loginActive ? registerActiveFunc() : loginActiveFunc();
  };

  const registerActiveFunc = () => {
    setLoginActive(false);
    setRegisterActive(true);
  };

  const loginActiveFunc = () => {
    setRegisterActive(false);
    setLoginActive(true);
  };

  const forgotPasswordFunc = () => {
    setLoginActive(false);
    setRegisterActive(false);
    setForgotPassActive(true);
  };

  const handleRememberMe = useCallback(() => {
    setRememberMe(!rememberMe);
  }, [rememberMe]);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className="auth-screen">
      <div className="left-column">
        <div className="content">
          <h1>
            Timeline tracking allowing users to save health data and share it to
            other people.
          </h1>
        </div>
      </div>
      <div className="right-column">
        <div className="logo-wrapper">
          <Image
            src={logo}
            style={{ marginBottom: "20px", backgroundColor: "transparent" }}
          />
        </div>
        <div className="middle">
          {loginActive && (
            <>
              <h1 className="title">Login To Your Account</h1>
              <Form
                onSubmit={() => {
                  dispatch(
                    login({
                      emailLogin,
                      passwordLogin,
                      rememberMe,
                      setIsLoading,
                    })
                  );
                }}
              >
                <Form.Input
                  type="email"
                  placeholder="Email"
                  label="Email"
                  onChange={(e) => {
                    setEmailLogin(e.target.value);
                  }}
                />
                <div className="password-wrapper">
                  <Form.Input label="Password">
                    <input
                      onChange={(e) => setPasswordLogin(e.target.value)}
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                    />
                    <Icon
                      name={showPassword ? "eye slash" : "eye"}
                      onClick={handleShowPassword}
                    />
                  </Form.Input>
                </div>
                <div className="remember">
                  <Checkbox checked={rememberMe} onChange={handleRememberMe} />
                  <span>Stay Logged In</span>
                </div>
                <div className="btn-wrap">
                  <Button
                    positive
                    type="submit"
                    onClick={() => {
                      dispatch(
                        login({
                          emailLogin,
                          passwordLogin,
                          rememberMe,
                          setIsLoading,
                        })
                      );
                    }}
                    className="btn-white"
                  >
                    {isLoading ? (
                      <>
                        <Spinner />
                        Loading...
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Button
                    type="reset"
                    onClick={() => {
                      changeAuthMode();
                    }}
                    className="btn-blue"
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="forgot-password">
                  <p
                    className="green"
                    style={{ marginBottom: "15px", cursor: "pointer" }}
                    onClick={() => {
                      forgotPasswordFunc();
                    }}
                  >
                    Forgot Password?
                  </p>
                </div>
              </Form>
            </>
          )}
          {registerActive && (
            <>
              <h1 className="title">Create Your Account</h1>
              <Form
                onSubmit={() => {
                  dispatch(
                    register({
                      emailSignup,
                      passwordSignup,
                      setIsLoading,
                      username,
                    })
                  );
                }}
              >
                <Form.Input
                  type="text"
                  placeholder="Username"
                  label="Username"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                <Form.Input
                  type="email"
                  placeholder="Email"
                  label="Email"
                  onChange={(e) => {
                    setEmailSignup(e.target.value);
                  }}
                />
                <div className="password-wrapper">
                  <Form.Input label="Password">
                    <input
                      onChange={(e) => setPasswordSignup(e.target.value)}
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                    />
                    <Icon
                      name={showPassword ? "eye slash" : "eye"}
                      onClick={handleShowPassword}
                    />
                  </Form.Input>
                </div>
                <div className="btn-wrap">
                  <div className="remember" style={{ marginBottom: "15px" }}>
                    <Checkbox
                      onClick={() => {
                        setAgeApproval(!ageApproval);
                      }}
                      checked={ageApproval}
                    />
                    <span>I am 18+ years old</span>
                  </div>
                  <Button
                    type="reset"
                    onClick={() => {
                      dispatch(
                        register({
                          emailSignup,
                          passwordSignup,
                          setIsLoading,
                          username,
                        })
                      );
                    }}
                    className="btn-white"
                    disabled={!ageApproval}
                  >
                    {isLoading ? (
                      <>
                        <Spinner />
                        Loading...
                      </>
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                  <Button
                    positive
                    type="submit"
                    onClick={() => {
                      changeAuthMode();
                    }}
                    className="btn-blue"
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </>
          )}
          {forgotPassActive && (
            <>
              <h1 className="title">Forgot your Password?</h1>
              <p>Enter your registered email to reset the password.</p>
              <Form>
                <Form.Input
                  type="email"
                  placeholder="Email"
                  label="Email"
                  onChange={(e) => {
                    setEmailForgotPass(e.target.value);
                  }}
                />

                <div className="btn-wrap">
                  <Button
                    onClick={() => {
                      dispatch(
                        forgotPassword({
                          emailForgotPass,
                          setIsLoading,
                          setOpenModal,
                        })
                      );
                    }}
                    className="btn-white"
                  >
                    {isLoading ? (
                      <>
                        <Spinner />
                        Sending...
                      </>
                    ) : (
                      "Send"
                    )}
                  </Button>
                  <Button
                    positive
                    onClick={() => {
                      changeAuthMode();
                    }}
                    className="btn-blue"
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
        <div
          className="privacy"
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy and Terms of Service
        </div>
      </div>
    </div>
  );
};

export { Auth };
