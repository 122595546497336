const MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000

const create = ({ start, end, zoom, viewportWidth = 0, minWidth = 0 }) => {
  const duration = end - start

  const days = duration / MILLIS_IN_A_DAY
  const daysZoomWidth = days * zoom

  let timelineWidth

  // console.log({ daysZoomWidth })
  // console.log({ viewportWidth })

  if (daysZoomWidth > viewportWidth) {
    timelineWidth = daysZoomWidth
  } else {
    timelineWidth = viewportWidth
  }

  if (timelineWidth < minWidth) {
    timelineWidth = minWidth
  }

  const timelineWidthStyle = `${timelineWidth}px`

  const toX = from => {

    const value = (from - start) / duration
    return Math.round(value * timelineWidth)
  }

  const toStyleLeft = from => ({
    left: `${toX(from)}px`,
  })

  const toStyleLeftAndWidth = (from, to, startDate = false, endDate = false) => {
    const left = toX(from)
    const styles = {
      left: `${left}px`,
      width: `${toX(to) - left}px`
    }

    if (startDate && endDate) {
      styles.backgroundColor = startDate === 1 && endDate === 2 ? "rgba(39,102,183, 0.15)" : "transparent"
    }

    return styles
  }

  const fromX = x => new Date(start.getTime() + (x / timelineWidth) * duration)

  return {
    end,
    fromX,
    start,
    timelineWidth,
    timelineWidthStyle,
    toStyleLeft,
    toStyleLeftAndWidth,
    toX,
    zoom,
  }
}

export default create
