import React from "react";
import { Button, Modal, Table } from "semantic-ui-react";

const ListTimelinesModal = ({
  selectedTrackListModal,
  setSelectedTrackListModal,
}) => (
  <>
    {selectedTrackListModal && (
      <Modal open={selectedTrackListModal ? true : false} size="tiny">
        <Modal.Header>
          <h4>
            <span
              style={{
                backgroundColor:
                  selectedTrackListModal[0].style.backgroundColor,
                float: "left",
                marginRight: "10px"
              }}
              className="circle"
            ></span>
            {selectedTrackListModal[0].title}
          </h4>
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Cell>Start Date</Table.Cell>
                <Table.Cell>End Date</Table.Cell>
                <Table.Cell>Intensity</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {selectedTrackListModal
                .sort((a, b) => (a.start < b.start ? 0 : 1))
                .map(({ end, intensity, start }) => (
                  <Table.Row>
                    <Table.Cell>{new Date(start).toDateString()}</Table.Cell>
                    <Table.Cell>{new Date(end).toDateString()}</Table.Cell>
                    <Table.Cell>{intensity}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            icon="cancel"
            negative
            onClick={() => {
              setSelectedTrackListModal(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    )}
  </>
);

export default ListTimelinesModal;
