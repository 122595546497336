import { navigate } from "@reach/router";

import { dispatchError, postReq } from "../../../api";
import { clearSuccessMessage, setSuccessMessage } from "../../../redux/errorSlice";

const createNewPassword = (password, token) => async (dispatch) => {
    try {
        const res = await postReq("/api/auth/createNewPassword", { password }, token)

        if (res.status === 200) {
            dispatch(setSuccessMessage("Password reset!"));
            setTimeout(() => {
                navigate("/");
            }, 3000);
            setTimeout(() => {
                dispatch(clearSuccessMessage());
            }, 5000);
        } else {
            dispatchError(res.data)
        }
    } catch (err) {
        dispatchError(err)
    }
}

export {
    createNewPassword
}