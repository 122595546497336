import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearSheetHistory } from "../../redux/dataSlice";
import { setZoomLevel } from "../../redux/userSlice";
import { Background, MemoizedCustomTimeline, Navigation } from "../../components";
import { MobileModal } from "../../modals";

const TimelineView = ({ shareId }) => {
  const userConfig = useSelector((state) => state.user.userData.user.config);
  const zoomLevel = useSelector((state) => state.user.zoomLevel);
  const [scrollPos, setScrollPos] = useState(0);
  const [showLegend, setShowLegend] = useState(false);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const dispatch = useDispatch();

  const currentScrollPos = useCallback((val) => {
    setScrollPos(val);
  }, []);

  const timeObject = useCallback((val) => {
    setTimelineWidth(val.timelineWidth);
  }, []);

  const wheelListener = (event) => {
    if (event.shiftKey && event.altKey) {
      event.stopPropagation();
      event.preventDefault();
    } else if (event.shiftKey && event.ctrlKey) {
      event.stopPropagation();
      event.preventDefault();
      event.cancelBubble = false;
      document.getElementById('faux-scroll-wrap').scrollLeft += event.deltaY;
    } else if (event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      event.cancelBubble = false;
      const change = event.deltaY > 0 ? 1 : -1;
      dispatch(setZoomLevel(zoomLevel + change));
    } 
  };
  
  useEffect(() => {
      dispatch(clearSheetHistory());
      setTimeout(function(){
        // scroll offset for current day on load
        const today = document.querySelector('.today')
        if (today !== null) {
          const todayCellBounds = today.getBoundingClientRect();
          document.getElementById('faux-scroll-wrap').scrollLeft = (todayCellBounds.x - (window.innerWidth / 2));
        }
      }, 2000);
  }, []);

  useEffect(() => {
      window.innerWidth > 899
        ? window.addEventListener("wheel", wheelListener)
        : window.removeEventListener("wheel", wheelListener);

      return () => window.removeEventListener("wheel", wheelListener);
  }, [zoomLevel]);

  return (
    <div
      id="timeline-view"
      style={{
        minHeight: "100%",
        backgroundColor: "rgba(255,255,255,0)",
      }}
    >
      <Background index={userConfig} />
      <Navigation
        showLegend={showLegend}
        setShowLegend={setShowLegend}
        shareId={shareId}
        timelineWidth={timelineWidth}
        scrollPos={scrollPos}
      />
      <MemoizedCustomTimeline
        showLegend={showLegend}
        shareId={shareId}
        currentScrollPos={currentScrollPos}
        timeObject={timeObject}
      />
      <MobileModal />
    </div>
  );
};  

export { TimelineView };
