import React from "react";
import PropTypes from "prop-types";

import Cell from "./Cell";

const Row = ({ id, time, pointerDate, cells, style }) => {
    let display = false;

    if (
        (id === "quarters" && time.zoom <= 10) ||
        id === "months" ||
        (id === "weeks" && time.zoom > 10) ||
        (id === "days" && time.zoom >= 20)
    ) {
        display = true;
    }
    return (
        <React.Fragment>
            {display && (
                <div className="rt-timebar__row" style={style} id={id}>
                    {cells.map((cell) => {
                        return <Cell key={cell.id} time={time} pointerDate={pointerDate} {...cell} cell={cell} />;
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

Row.propTypes = {
    time: PropTypes.shape({}).isRequired,
    cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    style: PropTypes.shape({})
};

export default Row;
