import React, { Component } from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Body from "./Body";
import NowMarker from "./Marker/Now";
import PointerMarker from "./Marker/Pointer";
import getMouseX from "../../utils/getMouseX";
import getGrid from "../../utils/getGrid";

class Timeline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pointerDate: null,
            pointerVisible: false,
            pointerHighlighted: false,
            scrollLeftVal: 0,
            mouseX: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.parentScrollLeftVal !== prevProps.parentScrollLeftVal) {
            this.setScrollLeftVal(this.props.parentScrollLeftVal);
        }
    }

    handleMouseMove = (e) => {
        //Just a comment to test
        const { time } = this.props;
        this.setState({ mouseX: e.clientX });
        this.setState({ pointerDate: time.fromX(getMouseX(e)) });
    };

    handleMouseLeave = () => {
        this.setState({ pointerHighlighted: false });
    };

    handleMouseEnter = () => {
        this.setState({ pointerVisible: true, pointerHighlighted: true });
    };

    setScrollLeftVal = (val) => {
        let leftOffset;

        if (window.innerWidth < 768) {
            leftOffset = 20;
        } else {
            leftOffset = 100;
        }
        const { time } = this.props;
        this.setState({ scrollLeftVal: val });
        this.setState({ pointerDate: time.fromX(val - leftOffset + this.state.mouseX) }); //100 exists becaue of the margin at the left of the entire application
        this.props.scrollRef.current.scrollLeft = val ? val : 0;
        // this.props.currentScrollPos(val);
    };

    render() {
        const { now, time, timebar, tracks, sticky, clickElement, addElement, addElementId } = this.props;
        const { pointerDate, pointerVisible, pointerHighlighted, scrollLeftVal } = this.state;
        const grid = getGrid(timebar);

        return (
            <div
                // className="rt-timeline"
                style={{ width: time.timelineWidthStyle }}
                className={addElement() === "undefined" ? "editing" : "readonly"}
            >
                {now && <NowMarker now={now} visible time={time} scrollLeftVal={scrollLeftVal} />}
                {pointerDate && time.zoom < 20 && (
                    <PointerMarker
                        date={pointerDate}
                        time={time}
                        visible={pointerVisible}
                        highlighted={pointerHighlighted}
                        scrollLeftVal={scrollLeftVal}
                    />
                )}
                <Header
                    time={time}
                    timebar={timebar}
                    //pointerDate={pointerDate} // This is for automatic highlighting of header cells on hover
                    onMove={this.handleMouseMove}
                    onEnter={this.handleMouseEnter}
                    onLeave={this.handleMouseLeave}
                    width={time.timelineWidthStyle}
                    sticky={sticky}
                    setScrollLeftVal={this.setScrollLeftVal}
                    scrollLeftVal={scrollLeftVal}
                />
                <Body
                    time={time}
                    grid={grid}
                    timebar={timebar}
                    tracks={tracks}
                    clickElement={clickElement}
                    addElement={addElement}
                    addElementId={addElementId}
                    scrollLeft={scrollLeftVal}
                    setScrollLeftVal={this.setScrollLeftVal}
                    scrollLeftVal={scrollLeftVal}
                />
            </div>
        );
    }
}

Timeline.propTypes = {
    now: PropTypes.instanceOf(Date),
    time: PropTypes.shape({
        fromX: PropTypes.func.isRequired,
        toStyleLeftAndWidth: PropTypes.func,
        timelineWidthStyle: PropTypes.string
    }).isRequired,
    timebar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string
        }).isRequired
    ).isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    sticky: PropTypes.shape({}),
    clickElement: PropTypes.func,
    addElement: PropTypes.func,
    addElementId: PropTypes.func,
    parentScrollLeftVal: PropTypes.number
};

export default Timeline;
