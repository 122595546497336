import React from "react";

import icon1 from "../../assets/icons/timeline/1.svg";
import icon2 from "../../assets/icons/timeline/2.svg";
import icon3 from "../../assets/icons/timeline/3.svg";
import icon4 from "../../assets/icons/timeline/4.svg";

const renderSheetTitle = (sheetTitleNumber) => {
    switch (sheetTitleNumber) {
        case 1:
            return (
                <div className="current-timeline">
                    <div className="icon-wrapper">
                        <img src={icon1} alt="icon" />
                        <h3>
                            <span className="title-prefix">Track </span>Symptoms
                        </h3>
                    </div>
                </div>
            );
        case 2:
            return (
                <div className="current-timeline">
                    <div className="icon-wrapper">
                        <img src={icon2} alt="icon" />
                        <h3>
                            <span className="title-prefix">Track </span>Medication
                        </h3>
                    </div>
                </div>
            );
        case 3:
            return (
                <div className="current-timeline">
                    <div className="icon-wrapper">
                        <img src={icon3} alt="icon" />
                        <h3>
                            <span className="title-prefix">Track </span>Diet
                        </h3>
                    </div>
                </div>
            );
        case 4:
            return (
                <div className="current-timeline">
                    <div className="icon-wrapper">
                        <img src={icon4} alt="icon" />
                        <h3>
                            <span className="title-prefix">Track </span>Anything
                        </h3>
                    </div>
                </div>
            );
    }
};

const zoomOptions = [
    {
        key: "week",
        text: "This Week",
        // value: 11,
        value: "week",
    },
    {
        key: "month",
        text: "This Month",
        // value: 8,
        value: "month",
    },
    {
        key: "quarter",
        text: "This Quarter",
        // value: 5,
        value: "quarter",
    },
    {
        key: "year",
        text: "This Year",
        // value: 3,
        value: "year",
    },
    {
        key: "beginning",
        text: "The Beginning",
        // value: 3,
        value: "beginning",
    },
];

export { renderSheetTitle, zoomOptions }