import React, { useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import "./../../scss/accountCustomizer.scss";
import { postUpdateUser } from "./actions";

const AccountCustomizer = () => {
  const userData = useSelector((state) => state.user.userData);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <Popup
      id="account-customizer"
      trigger={
        <Button
          icon="pencil"
          onClick={() => {
            setOpen(!open);
          }}
        />
      }
      on="click"
      open={open}
      onOpen={() => {
        setOpen(!open);
      }}
    >
      <Popup.Header>Select Background</Popup.Header>
      <Popup.Content>
        {new Array(12).fill(null).map((falsey, idx) => (
            <div
              className={`bg-thumb ${idx}`}
              key={idx}
              onClick={async () => {
                  await dispatch(postUpdateUser({
                    id: userData.user.id,
                    userId: userData.user.id,
                    config: { bg: idx },
                  }, userData.token))
                  setOpen(false)
                }
              }
            />
          )
        )}
      </Popup.Content>
      <button
        className="btn-red"
        onClick={async () => {
            await dispatch(postUpdateUser({
              id: userData.user.id,
              userId: userData.user.id,
              config: { bg: -1 },
            }, userData.token))
          }
        }
      >
        Reset
      </button>
    </Popup>
  );
};

export { AccountCustomizer }