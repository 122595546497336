import React, { useEffect, useState } from "react";
import { Segment, Dimmer, Loader, Button } from "semantic-ui-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  createSheet,
  fetchAllSheets,
  fetchSheet,
  fetchAllSheetShared,
  fetchSheetShared,
} from "./actions";
import { logoutUser, setUserData } from "../../redux/userSlice";
import { setSelectedSheetTitle } from "../../redux/dataSlice";

import { QUOTES } from "../../constants";
import { AccountCustomizer, Background } from "../../components";
import { ShareModal } from "../../modals";

import logo from "../../assets/Chronowell_Logo-White.png";
import icon1 from "../../assets/icons/home/1.svg";
import icon2 from "../../assets/icons/home/2.svg";
import icon3 from "../../assets/icons/home/3.svg";
import icon4 from "../../assets/icons/home/4.svg";
import icon5 from "../../assets/icons/home/5.svg";

import "../../scss/home.scss";

const Home = ({ shareId }) => {
  const sheets = useSelector((state) => state.data.sheets);
  const sheetsLib = useSelector((state) => state.data.sheetsLib);
  const userConfig = useSelector((state) => state.user.userData.user.config);
  const userDetails = useSelector(
    (state) => state.user.userData.user,
    shallowEqual
  );
  const userName = useSelector(
    (state) => state.user.userData.user.name,
    shallowEqual
  );
  const userToken = useSelector((state) => state.user.userData.token);
  const [shareModal, setShareModal] = useState(false);
  const [sheetIsLoading, setSheetIsLoading] = useState(false);
  const dispatch = useDispatch();

  let userId;

  if (!shareId && userDetails) {
    userId = userDetails.id;
  }

  useEffect(() => {
    if (shareId) {
      console.log("shareId: " + shareId);
      dispatch(fetchAllSheetShared({ code: shareId }));
      dispatch(setUserData({ user: JSON.parse(JSON.stringify(userDetails)) }))
    }
  }, [shareId]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchAllSheets({ userId, userToken }));
    }
  }, [userId]);

  const checkSheetShared = (type) => {
    let sheet = sheets.find((sheet) => {
      if (
        sheet.config &&
        typeof sheet.config === "object" &&
        sheet.config !== null
      ) {
        return sheet.config.type == type;
      }
      return false;
    });
    if (!sheet) {
      return false;
    } else {
      return sheet;
    }
  };

  const selectSheetShared = (type) => {
    if (checkSheetShared(type)) {
      const id = checkSheetShared(type).id;
      dispatch(fetchSheetShared({ id, shareId }));
    }
  };

  const selectSheet = async (type) => {
    dispatch(setSelectedSheetTitle(type));
    // Find Sheet in state
    let sheet = sheets.find((sheet) => {
      if (
        sheet.config &&
        typeof sheet.config === "object" &&
        sheet.config !== null
      ) {
        if (sheet.config.type === type) {
          return true;
        }
      }
      return false;
    });
    if (!sheet) {
      // Create new Sheet
      const body = {
        ...sheetsLib[type],
        userId: userId,
      };
      await dispatch(
        createSheet({ body, setSheetIsLoading, userToken })
      );
    } else {
      // Get sheet data
      await dispatch(
        fetchSheet({
          id: sheet.id,
          setSheetIsLoading,
          userId,
          userToken,
        })
      );
    }
  };

  return (
    <div id="home-wrapper">
      <Background index={userConfig} />
      <div className="home-header">
        <div className="logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        {!shareId && (
          <div className="user-info">
            <h4>{userName}</h4>
            <div>
              <AccountCustomizer />
              &nbsp;&nbsp;
              {window.innerWidth < 480 ? (
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("chronowellApp");
                    dispatch(logoutUser());
                  }}
                  icon="cancel"
                />
              ) : (
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("chronowellApp");
                    dispatch(logoutUser());
                  }}
                >
                  Logout
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div id="menuSplash">
        <Segment>
          <h2
            id="quote"
            style={{
              color: userConfig && userConfig.bg == 8 ? "white" : "black",
            }}
          >
            {QUOTES[Math.floor(Math.random() * QUOTES.length)]}
          </h2>

          <div id="menuSplashGrid">
            <div
              id="symptoms"
              className={`menuChoice ${
                !shareId || (shareId && checkSheetShared(1))
                  ? "enabled"
                  : "disabled"
              }`}
              onClick={(e) => {
                shareId ? selectSheetShared(1) : selectSheet(1);
              }}
            >
              <div className="img-wrapper">
                <img src={icon2} alt="symptoms" />
              </div>
              <p>{shareId ? "Shared Symptoms" : "Track Symptoms"}</p>
            </div>
            <div
              id="medication"
              className={`menuChoice ${
                !shareId || (shareId && checkSheetShared(2))
                  ? "enabled"
                  : "disabled"
              }`}
              onClick={(e) => {
                shareId ? selectSheetShared(2) : selectSheet(2);
              }}
            >
              <div className="img-wrapper">
                <img src={icon3} alt="medication" />
              </div>
              <p>{shareId ? "Shared Medication" : "Track Medication"}</p>
            </div>
            <div
              id="diet"
              className={`menuChoice ${
                !shareId || (shareId && checkSheetShared(3))
                  ? "enabled"
                  : "disabled"
              }`}
              onClick={(e) => {
                shareId ? selectSheetShared(3) : selectSheet(3);
              }}
            >
              <div className="img-wrapper">
                <img src={icon4} alt="diet" />
              </div>
              <p>{shareId ? "Shared Diet" : "Track Diet"}</p>
            </div>
            <div
              id="anything"
              className={`menuChoice ${
                !shareId || (shareId && checkSheetShared(4))
                  ? "enabled"
                  : "disabled"
              }`}
              onClick={(e) => {
                shareId ? selectSheetShared(4) : selectSheet(4);
              }}
            >
              <div className="img-wrapper">
                <img src={icon5} alt="custom'" />
              </div>
              <p>{shareId ? "Shared Custom" : "Track Anything"}</p>
            </div>
            {!shareId && (
              <div
                id="share"
                className="menuChoice"
                onClick={() => {
                  setShareModal(true);
                }}
              >
                <div className="img-wrapper">
                  <img src={icon1} alt="share tracker" />
                </div>
                <p>Share Tracker</p>
              </div>
            )}
          </div>
        </Segment>
      </div>
      {sheetIsLoading && (
        <div className="home-loader">
          <Dimmer active inverted>
            <Loader content="" />
          </Dimmer>
        </div>
      )}
      {shareId === undefined && (
        <ShareModal
          shareModal={shareModal}
          setShareModal={setShareModal}
          shareId={shareId}
        />
      )}
    </div>
  );
};

// const MemoizedHome = memo(Home);

const MemoizedHome = Home;

export { MemoizedHome };
