import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Form, Checkbox, Button, Modal, Table } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import "../scss/share.scss";
import { setSuccessMessage, clearSuccessMessage } from "../redux/errorSlice";
import {
  createShareKey,
  deleteShareKey,
  fetchSheetKeys,
  linkSheetKey,
  unlinkSheetKey,
} from "./actions";

import icon2 from "../assets/icons/home/2.svg";
import icon3 from "../assets/icons/home/3.svg";
import icon4 from "../assets/icons/home/4.svg";
import icon5 from "../assets/icons/home/5.svg";

const ShareModal = ({ shareModal, setShareModal }) => {
  const auth = useSelector((state) => state.user.userData);
  const keys = useSelector((state) => state.user.userData.keys);
  const sheetData = useSelector((state) => state.data.sheets);
  const [isLoading, setLoading] = useState(false);
  const [newKey, setNewKey] = useState({ name: "New Key" });
  const [shareURL, setURL] = useState("");
  const [values, setValues] = useState(false);
  const shareURLRef = React.useRef(null);
  const dispatch = useDispatch();

  const keyHasSheetType = (key, type) => {
    return key.Sheets.filter((sheet) => sheet.config.type === type).length > 0;
  };

  useEffect(() => {
    const newVals = {};
    for (let i = 0; i < sheetData.length; i++) {
      if (sheetData[i].SheetKeyId > 0 && 0 < sheetData[i].config.type < 5) {
        newVals[sheetData[i].config.type] = true;
      }
    }
    setValues(newVals);
  }, [sheetData]);

  useEffect(() => {
    dispatch(fetchSheetKeys({ token: auth.token, userId: auth.user.id }));
  }, []);

  useEffect(() => {
    if (newKey.name !== "New Key") {
      sharePredefinedTimeline();
    }
  }, [newKey])

  const sharePredefinedTimeline = async () => {
    if (newKey && newKey.name !== "New Key") {
      const type = newKey.type;
      delete newKey.type
      const createdKey = await createKey();
      linkSheet(createdKey, type);
    }
  }

  const unlinkSheet = (key, type) => {
    let sheet = key.Sheets.filter((sheet) => sheet.config.type === type);
    let sheetId = sheet.length ? sheet[0].id : null;

    dispatch(
      unlinkSheetKey({
        id: key.id,
        setLoading,
        sheetId,
        token: auth.token,
        userId: auth.user.id,
      })
    );
  };

  const linkSheet = (key, type) => {
    let sheet = sheetData.filter((sheet) => sheet.config.type === type);
    let sheetId = sheet.length ? sheet[0].id : null;

    dispatch(
      linkSheetKey({
        id: key.id,
        setLoading,
        sheetId,
        token: auth.token,
        userId: auth.user.id,
      })
    );
  };

  const createKey = () => {
    return dispatch(
      createShareKey({
        name: newKey.name,
        setLoading,
        token: auth.token,
        userId: auth.user.id,
      })
    );
  };

  const deleteKey = (key) => {
    dispatch(
      deleteShareKey({
        id: key.id,
        setLoading,
        token: auth.token,
        userId: auth.user.id,
      })
    );
  };

  const tableCellCheckbox = (key, type) => {
    let checked = keyHasSheetType(key, type);
    return (
      <Checkbox
        checked={checked}
        disabled={isLoading}
        name="custom"
        onChange={(e) => {
          checked ? unlinkSheet(key, type) : linkSheet(key, type);
        }}
      />
    );
  };

  const copyLink = (e) => {
    shareURLRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    dispatch(setSuccessMessage("Link Copied!"));
    setTimeout(() => {
      dispatch(clearSuccessMessage());
    }, 5000);
  };

  const handleCopiedToClipboard = () => {
    dispatch(setSuccessMessage("Link Copied!"));
    setTimeout(() => {
      dispatch(clearSuccessMessage());
    }, 5000);
  };

  return (
    <Modal
      className="share-modal"
      dimming="blurring"
      id="shareModal"
      onClose={() => setShareModal(false)}
      open={shareModal ? shareModal : false}
      size={"large"}
    >
      <Modal.Header>
        <h5>Share Tracker Timeline</h5>
      </Modal.Header>
      <Modal.Content>
        <div className="previously-shared">
          <h5>Previously Shared</h5>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {/* <Table.HeaderCell>Memo</Table.HeaderCell> */}
                <Table.HeaderCell>Link</Table.HeaderCell>
                <Table.HeaderCell>
                  <img src={icon2} alt="icon" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <img src={icon3} alt="icon" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <img src={icon4} alt="icon" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <img src={icon5} alt="icon" />
                </Table.HeaderCell>
                <Table.HeaderCell>Copy</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {keys &&
                keys.map((item, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell style={{ cursor: "pointer" }}>
                        <i
                          className="delete icon"
                          onClick={() => {
                            deleteKey(item);
                          }}
                        ></i>
                      </Table.Cell>
                      {/* <Table.Cell>{item.name}</Table.Cell> */}
                      <Table.Cell>
                        <a
                          href={`https://chronowell.app/shared/${item.code}`}
                          target="_blank"
                        >
                          {`https://chronowell.app/shared/${item.code}`}
                        </a>
                      </Table.Cell>
                      <Table.Cell>{tableCellCheckbox(item, 1)}</Table.Cell>
                      <Table.Cell>{tableCellCheckbox(item, 2)}</Table.Cell>
                      <Table.Cell>{tableCellCheckbox(item, 3)}</Table.Cell>
                      <Table.Cell>{tableCellCheckbox(item, 4)}</Table.Cell>
                      <Table.Cell
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <CopyToClipboard
                          text={`https://chronowell.app/shared/${item.code}`}
                        >
                          <i
                            className="linkify icon"
                            style={{ color: "blue", margin: "auto" }}
                            onClick={handleCopiedToClipboard}
                          ></i>
                        </CopyToClipboard>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
        <div>
          <h5>Create New Share Link</h5>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div className="menuChoice">
              <Button
                onClick={(e) => {
                  setNewKey({
                    ...newKey,
                    name: `Share_Link_${(1000 + Math.random() * 9000).toFixed(
                      0
                    )}`,
                    type: 1,
                  });
                }}
              >
                <div className="img-wrapper">
                  <img src={icon2} />
                </div>
                <p>Share Symptoms</p>
              </Button>
            </div>
            <div className="menuChoice">
              <Button
                onClick={async (e) => {
                  await setNewKey({
                    ...newKey,
                    name: `Share_Link_${(1000 + Math.random() * 9000).toFixed(
                      0
                    )}`,
                    type: 2,
                  });
                }}
              >
                <div className="img-wrapper">
                  <img src={icon3} />
                </div>
                <p>Share Medications</p>
              </Button>
            </div>
            <div className="menuChoice">
              <Button
                onClick={async (e) => {
                  await setNewKey({
                    ...newKey,
                    name: `Share_Link_${(1000 + Math.random() * 9000).toFixed(
                      0
                    )}`,
                    type: 3,
                  });
                }}
              >
                <div className="img-wrapper">
                  <img src={icon4} />
                </div>
                <p>Share Diet</p>
              </Button>
            </div>
            <div className="menuChoice">
              <Button
                onClick={async (e) => {
                  await setNewKey({
                    ...newKey,
                    name: `Share_Link_${(1000 + Math.random() * 9000).toFixed(
                      0
                    )}`,
                    type: 4,
                  });
                }}
              >
                <div className="img-wrapper">
                  <img src={icon5} />
                </div>
                <p>Share Custom</p>
              </Button>
            </div>
          </div>
        </div>
        {/* <Form>
          <div>
            <h5>Create New Share Link</h5>
            <div className="ui action input create-share">
              <input
                name="name"
                onChange={(e) => setNewKey({ ...newKey, name: e.target.value })}
                value={newKey.name}
              />
              <CopyToClipboard text={newKey.name}>
                <button
                  className="ui teal right labeled icon button"
                  onClick={handleCopiedToClipboard}
                >
                  <i className="copy icon"></i>
                  Copy
                </button>
              </CopyToClipboard>
            </div>
            <Button negative onClick={createKey}>
              Create
            </Button>
          </div>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            if (setShareModal) {
              setShareModal(false);
            }
          }}
        >
          Close
        </Button>
        {/* <Button style={{}} onClick={copyLink}>
                    Generate
                </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

export { ShareModal };
