import React, { useRef } from "react";
import PropTypes from "prop-types";

import Tracks from "./Tracks";
import Grid from "./Grid";

const throttle = (callback, limit) => {
    var wait = false; // Initially, we're not waiting
    return function () {
        // We return a throttled function
        if (!wait) {
            // If we're not waiting
            callback.call(); // Execute users function
            wait = true; // Prevent future invocations
            setTimeout(function () {
                // After a period of time
                wait = false; // And allow future invocations
            }, limit);
        }
    };
};

const Body = ({
    addElement,
    addElementId,
    clickElement,
    grid,
    scrollLeftVal,
    setScrollLeftVal,
    time,
    timebar,
    tracks,
}) => {
    const scrollRef = useRef();

    return (
        <div className="rt-timeline__body" ref={scrollRef}>
            {grid && <Grid time={time} grid={grid} timebar={timebar} />}
            <Tracks
                time={time}
                tracks={tracks}
                clickElement={clickElement}
                addElement={addElement}
                addElementId={addElementId}
            />
        </div>
    );
};

Body.propTypes = {
    addElement: PropTypes.func,
    addElementId: PropTypes.func,
    clickElement: PropTypes.func,
    grid: PropTypes.arrayOf(PropTypes.shape({})),
    time: PropTypes.shape({}).isRequired,
    timebar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string
        }).isRequired
    ).isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    setScrollLeftVal: PropTypes.func.isRequired
};

export default Body;
