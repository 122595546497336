import axios from 'axios'

import { setErrorMessage } from "../redux/errorSlice";
import store from "../redux/store";

const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://chronowell.app'

export const postReq = async (path, obj, token = false) => {
    try {
		if (token) {
			const response = await axios.post(URL + path, obj, {
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.post(URL + path, obj)
			return response
		}
    } catch (error) {
		throw error
    }
}

export const putReq = async (path, obj, token = false) => {
    try {
		if (token) {
			const response = await axios.put(URL + path, obj, {
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.put(URL + path, obj)
			return response
		}
    } catch (error) {
		throw error
    }
}

export const getReq = async (path, obj, token = false) => {
    try {
		if (token) {
			const response = await axios.get(URL + path, {
				params: obj,
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.get(URL + path, {params: obj})
			return response
		}
    } catch (error) {
        throw error
    }
}

export const deleteReq = async (path, obj, token = false) => {
    try {
		if (token) {
			const response = await axios.delete(URL + path, {
				params: obj,
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.get(URL + path, {params: obj})
			return response
		}
    } catch (error) {
        throw error
    }	
}

export const dispatchError = (err) => {
	store.dispatch(
	  setErrorMessage(err.message !== undefined ? err.message : err.toString())
	);
	setTimeout(() => {
	  store.dispatch(setErrorMessage(''));
	}, 5000);
  };