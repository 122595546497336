import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const sortTL = (sheet) => {
  if (sheet.Timelines && sheet.Timelines.length) {
    // check order for bad data
    let timelinesHaveOrder = true;

    sheet.Timelines.every((s) => {
      const key = Object.keys(s.config)[0]
      const subconfig = s.config[key]
      timelinesHaveOrder = subconfig.hasOwnProperty('order')

      return timelinesHaveOrder
    })

    // if no order then reorder everthing 
    if (!timelinesHaveOrder) {
      sheet.Timelines.forEach((s, idx) => {
        const key = Object.keys(s.config)[0]
        const subconfig = s.config[key]
        subconfig.order = idx
      })
    } else {
      // else sort timelines
      sheet.Timelines.sort((a, b) => {
        let key = Object.keys(a.config)[0];
        let subconfig = a.config[key];
        let Aorder = 0
        if (subconfig.hasOwnProperty('order')) {
          Aorder = subconfig.order
        }
        key = Object.keys(b.config)[0];
        subconfig = b.config[key];
        let Border = 0
        if (subconfig.hasOwnProperty('order')) {
          Border = subconfig.order
        }
        return Aorder - Border
      })
    }
  }
  return sheet
}
const initialState = {
  sheets: [],
  sheetsLib: {
    1: {
      name: "Symptom Data",
      description: "keep data on what you're feeling",
      config: { type: 1 },
    },
    2: {
      name: "Diet Data",
      description: "keep data on what you're eating",
      config: { type: 2 },
    },
    3: {
      name: "Medication Data",
      description: "keep data on your medication",
      config: { type: 3 },
    },
    4: {
      name: "Custom Data",
      description: "keep data on whatever you like!",
      config: { type: 4 },
    },
  },
  selectedData: null,
  selectedSheetTitle: null, //This is actually identified by numbers,
  timelineHistory: [],
};

const mySlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    /**

      SHEET REDUCERS

    */
    getSheets: (state, action) => {
      state.sheets = action.payload;
    },
    getSheet: (state, action) => {
      let newSheet = action.payload;
      sortTL(newSheet)
      let sheets = state.sheets.slice();
      sheets = sheets.map((sheet) => {
        if (newSheet.id == sheet.id) {
          return newSheet;
        } else {
          return sheet;
        }
      });
      state.sheets = sheets;
    },
    newSheet: (state, action) => {
      let sheets = state.sheets.slice();
      sheets.push(action.payload);
      state.sheets = sheets;
    },
    updateSheet: (state, action) => {
      let newSheet = action.payload.sheet;
      let sheets = state.sheets.slice();
      sheets = sheets.map((sheet) => {
        if (newSheet.id === sheet.id) {
          return newSheet;
        } else {
          return sheet;
        }
      });
      state = { ...state, sheets };
    },
    deleteSheet: (state, action) => {
      let { sheetId } = action.payload;
      let sheets = state.sheets.slice();
      sheets = sheets.filter((sheet) => {
        if (sheetId === sheet.id) {
          return false;
        }
        return true;
      });
      state = { ...state, sheets };
    },
    addSheetHistory: (state, action) => {
      const oldTimelines = state.sheets.find((sheet) => {
        if (sheet.id === state.selectedData) {
          return true;
        }
      });
      const newHistorySheet = {
        ...action.payload,
        Timelines: oldTimelines.Timelines,
      };

      state.timelineHistory.unshift(newHistorySheet);
    },
    goBackSheetHistory: (state) => {
      // Sheet history pops from 0 index where 0 is most recent
      if (state.timelineHistory.length > 1) {
        // create a copy of the state
        const clonedState = JSON.parse(JSON.stringify(state));
        const lastHistory = clonedState.timelineHistory.shift();

        // store other sheets elsewhere
        const sheetToReplace = clonedState.sheets.find((sheet) => {
          if (sheet.id === lastHistory.sheet.id) {
            return sheet;
          }
        });
        const otherSheets = clonedState.sheets.filter((sheet) => {
          return sheet.id !== lastHistory.sheet.id;
        });

        // update sheets with timeline config history only
        const newSheets = [
          ...otherSheets,
          {
            ...sheetToReplace,
            config: clonedState.timelineHistory[0].sheet.config
          },
        ];

        state = {
          ...clonedState,
          sheets: newSheets,
        };

        return state
      }
    },
    clearSheetHistory: (state) => {
      state.timelineHistory = [];
    },

    /**

      TIMELINE REDUCERS

    */
    arrangeTimeline: (state, action) => {
      const timelineHistory = state.timelineHistory
      const { curr, next } = action.payload;
      // console.log({ curr, next })

      if (timelineHistory.length > 0) {
        const timelines = JSON.parse(JSON.stringify(timelineHistory[0].Timelines))
        const timelineToMove = JSON.parse(JSON.stringify(timelines[curr]));

        timelines.splice(curr, 1);

        if (next === 0) {
          state.timelineHistory[0].Timelines = [timelineToMove, ...timelines]
        } else if (next === timelines.length - 1) {
          state.timelineHistory[0].Timelines = [...timelines, timelineToMove]
        } else {
          // split array at future index
          const timelinesPostIndex = timelines.splice(next);
          // join all arrays
          const updatedTimelines = [...timelines, timelineToMove, ...timelinesPostIndex];
          state.timelineHistory[0].Timelines = updatedTimelines;
        }
      }
      return state;
    },
    newTimeline: (state, action) => {
      let timeline = action.payload;
      let sheets = state.sheets.slice();
      let newSheets = sheets.map((sheet) => {
        if (sheet.id == timeline.SheetId) {
          let timelines = sheet.Timelines;
          const timelineKey = Object.keys(timeline)[0]

          // Set Order for Newly Created Timelines
          if (timeline.config[timelineKey]) {
            timeline.config[timelineKey].order = timelines.length > 0 ? timeline.length + 1 : 0
          }

          if (timelines == null) {
            timelines = [];
          }
          timelines.push(timeline);
          sheet = { ...sheet, Timelines: timelines };
          // Updates Timeline History so not to break timeframe persistance and updating
          state.timelineHistory[state.timelineHistory.length - 1].Timelines = timelines;
        }
        return sheet;
      });
      state.sheets = newSheets;
      return state;
    },
    sortTimelines: (state, action) => {
      let sheetId = action.payload
      let sheets = state.sheets.slice()
      for (let i = 0; i < sheets.length; i++) {
        if (sheets[i].id == sheetId) {
          // console.log(JSON.stringify(sheets[i]), i)
          sortTL(sheets[i])
        }
      }
      return state
    },
    updateTimeline: (state, action) => {
      let timeline = action.payload;
      let sheets = state.sheets.slice();
      let newSheets = sheets.map((sheet) => {
        if (sheet.id == timeline.SheetId) {
          let timelines = sheet.Timelines;
          timelines = timelines.map((tl) => {
            if (timeline.id == tl.id) {
              return timeline;
            }
            return tl;
          });
          sheet = { ...sheet, Timelines: timelines };
          sortTL(sheet)
          // Updates Timeline History so not to break timeframe persistance and updating
          state.timelineHistory[state.timelineHistory.length - 1].Timelines = JSON.parse(JSON.stringify(sheet.Timelines));
        }
        return sheet;
      });
      // console.log("new sheets", newSheets);
      state.sheets = newSheets;
      return state;
    },
    updateSheetTimelines: (state, action) => {
      // Update History
      const history = JSON.parse(JSON.stringify(state.timelineHistory))
      const latestHistory = JSON.parse(JSON.stringify(history[0]))
      latestHistory.Timelines = action.payload
      sortTL(latestHistory)
      history.unshift(latestHistory)
      state.timelineHistory = history
      // Update Sheet
      const idx = state.sheets.findIndex((s) => s.id === action.payload[0].SheetId)
      state.sheets[idx].Timelines = action.payload
      sortTL(state.sheets[idx])
      return state
    },
    deleteTimeline: (state, action) => {
      let { timelineId, sheetId } = action.payload;
      let sheets = state.sheets.slice();
      let newSheets = sheets.map((sheet) => {
        if (sheet.id == sheetId) {
          let timelines = sheet.Timelines;
          timelines = timelines.filter((tl) => {
            if (tl.id === timelineId) {
              return false;
            }
            return true;
          });
          sheet = { ...sheet, Timelines: timelines };
          // Updates Timeline History so not to break timeframe persistance and updating
          state.timelineHistory[state.timelineHistory.length - 1].Timelines = timelines;
        }
        // console.log(sheet);
        return sheet;
      });
      state.sheets = newSheets;
      return state;
    },
    // OLD STUFF

    // Generic Errors for AJAX
    setSymptomData: (state, action) => {
      state.symptomData = action.payload;
      return state;
    },
    setDietData: (state, action) => {
      state.dietData = action.payload;
      return state;
    },
    setMedicationData: (state, action) => {
      state.medicationData = action.payload;
      return state;
    },
    setCustomData: (state, action) => {
      state.customData = action.payload;
      return state;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
      return state;
    },
    setStartDate: (state, action) => {
      state[state.selectedData].startDate = action.payload;
      return state;
    },
    updateTrack: (state, action) => {
      state[state.selectedData].data[action.payload.trackId].elements.map(
        (elem, index) => {
          if (elem.id === action.payload.id) {
            elem.start = action.payload.start;
            elem.end = action.payload.end;
            elem.title = action.payload.title;
            elem.style = action.payload.style;
            elem.description = action.payload.description;
            elem.intensity = action.payload.intensity;
          } else {
            elem.title = action.payload.title;
            elem.style = action.payload.style;
          }
        }
      );

      return state;
    },
    deleteTrack: (state, action) => {
      const { id, trackId, subtrackId } = action.payload;
      let trackCopy = _.cloneDeep(state[state.selectedData].data[trackId]);
      const elemIndex = trackCopy.elements.findIndex((elem) => elem.id === id);

      trackCopy.elements.splice(elemIndex, 1);

      if (trackCopy.elements.length > 0) {
        state[state.selectedData].data[trackId] = trackCopy;
      } else {
        state[state.selectedData].data = _.omit(
          state[state.selectedData].data,
          trackId
        );
      }

      return state;
    },
    deleteRow: (state, action) => {
      const { id, trackId } = action.payload;
      state[state.selectedData].data = _.omit(
        state[state.selectedData].data,
        trackId
      );
      return state;
    },
    setSelectedSheetTitle: (state, action) => {
      state.selectedSheetTitle = action.payload;
      return state;
    },
    updateSheetTimeSpan: (state, action) => {
      const { id, config, updatedAt } = action.payload;

      //Create a copy of the state
      const clonedState = JSON.parse(JSON.stringify(state));

      //Get the sheet that corresponds to the sheet that was just updated from the store
      let activeSheet = clonedState.sheets.filter((sheet) => {
        return sheet.id === id;
      });

      //store other sheets elsewhere
      const otherSheets = clonedState.sheets.filter((sheet) => {
        return sheet.id !== id;
      });

      //edit the sheet that corresponds to the sheet just updated
      //filter returns an array hence we just access the first element in the array
      activeSheet[0] = {
        ...activeSheet[0],
        config,
        updatedAt,
      };

      const newSheets = [...otherSheets, ...activeSheet];

      state = {
        ...state,
        sheets: newSheets,
      };
      return state;
    },
  },
});

export const {
  addSheetHistory,
  arrangeTimeline,
  clearSheetHistory,
  deleteSheet,
  deleteTimeline,
  getSheet,
  getSheets,
  goBackSheetHistory,
  newSheet,
  newTimeline,
  updateSheet,
  updateTimeline,
  updateSheetTimelines,
  sortTimelines,
  //Old ones
  deleteRow,
  deleteTrack,
  setCustomData,
  setDietData,
  setMedicationData,
  setSelectedData,
  setSelectedSheetTitle,
  setStartDate,
  setSymptomData,
  updateSheetTimeSpan,
  updateTrack,
} = mySlice.actions;
export default mySlice.reducer;
