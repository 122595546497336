import React from "react";

import "../scss/background.scss";

const Background = ({ index }) => (
  <div
    id="background"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg_pictures/${(index && index.bg) || (index && index.bg === 0) ? index.bg + 1 : null
        }.png)`,
    }}
  >
    {window.innerWidth > 767 && (
      <video
        src={`${process.env.PUBLIC_URL}/assets/bg_videos/${(index && index.bg) || (index && index.bg === 0)
            ? index.bg + 1
            : null
          }.webm`}
        type="video/webm"
        autoPlay={true}
        loop={true}
        muted
      ></video>
    )}
  </div>
);

export { Background };
