import React from "react";
import PropTypes from "prop-types";

import Track from "./Track";

const Tracks = ({ time, tracks, clickElement, addElement, addElementId }) => (
  <div className="rt-tracks">
    {tracks.map((item, index) => {
      const { _id, id, elements, isOpen, style, tracks: children, title } = item;
      return (
        <Track
          _id={_id}
          addElement={addElement}
          addElementId={addElementId}
          clickElement={clickElement}
          elements={elements}
          isOpen={isOpen}
          idx={index}
          key={index}
          time={time}
          title={title}
          trackId={id}
          tracks={children}
          style={style}
        />
      );
    })}
  </div>
);

Tracks.propTypes = {
  time: PropTypes.shape({}).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  clickElement: PropTypes.func,
  addElement: PropTypes.func,
  addElementId: PropTypes.func,
};

export default Tracks;
