import React from "react";
import { Icon } from "semantic-ui-react";

import "../scss/mobileModal.scss";

const MobileModal = () => (
  <div id="orientation-modal">
    <div id="inner-orientation-modal">
      <Icon name="redo" />
      <h1>
        Please rotate to<br></br> landscape orientation
      </h1>
    </div>
  </div>
);

export { MobileModal };
