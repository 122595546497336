import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Timebar from "./Timebar";

const noop = () => {};

const throttle = (callback, limit) => {
  var wait = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
};

const handleStickPlusIcon = (pos) => {
  let timeout;  
  const plusIcons = document.querySelectorAll(".rt-track-plus");
  plusIcons.forEach((icon) => {
    icon.style.opacity = 0;
    icon.style.left = `${pos}px`;
  });
  clearTimeout(timeout)
  timeout = setTimeout(function(){
      plusIcons.forEach((icon) => {
          icon.style.opacity = 1;
        });
  }, 1000)
};

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.scroll = React.createRef();
    this.timebar = React.createRef();
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    const { sticky } = this.props;
    if (sticky) {
      sticky.setHeaderHeight(this.timebar.current.offsetHeight);
      const { scrollLeft, isSticky } = sticky;
      if (isSticky) {
        this.scroll.current.scrollLeft = scrollLeft;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { sticky } = this.props;
    if (sticky) {
      const { scrollLeft, isSticky } = sticky;
      const prevScrollLeft = prevProps.sticky.scrollLeft;
      const prevIsSticky = prevProps.sticky.isSticky;
      if (scrollLeft !== prevScrollLeft || isSticky !== prevIsSticky) {
        this.scroll.current.scrollLeft = scrollLeft;
      }
    }

    this.scrollRef.current.scrollLeft = this.props.scrollLeftVal;
  }

  handleScroll = () => {
    const { sticky } = this.props;
    sticky.handleHeaderScrollY(this.scroll.current.scrollLeft);
  };

  handleStickPlusIcon = handleStickPlusIcon

  render() {
    const {
      time,
      pointerDate,
      onMove,
      onEnter,
      onLeave,
      width,
      timebar: rows,
      setScrollLeftVal,
      sticky: { isSticky, headerHeight, viewportWidth } = {},
    } = this.props;

    return (
      <div
        style={isSticky ? { paddingTop: headerHeight } : {}}
        onMouseMove={onMove}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <div
          className={`rt-timeline__header ${isSticky ? "rt-is-sticky" : ""}`}
          style={
            isSticky ? { width: viewportWidth, height: headerHeight + 10 } : {}
          }
        >
          <div
            className="rt-timeline__header-scroll"
            ref={this.scroll}
            onScroll={isSticky ? this.handleScroll : noop}
          >
            <div ref={this.timebar} style={isSticky ? { width } : {}}>
              <Timebar time={time} pointerDate={pointerDate} rows={rows} />
              <div
                id="faux-scroll-wrap"
                onScroll={(e) => {
                  this.handleStickPlusIcon(e.target.scrollLeft);
                  this.handleScroll();
                }}
                style={{ width: isSticky ? `${window.innerWidth}px` : "100%" }}
                ref={this.scrollRef}
              >
                <div id="faux-scroll-inner" style={{ width }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  time: PropTypes.shape({}).isRequired,
  timebar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  onMove: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  sticky: PropTypes.shape({
    isSticky: PropTypes.bool.isRequired,
    setHeaderHeight: PropTypes.func.isRequired,
    viewportWidth: PropTypes.number.isRequired,
    handleHeaderScrollY: PropTypes.func.isRequired,
    scrollLeft: PropTypes.number.isRequired,
  }),
  setScrollLeftVal: PropTypes.func.isRequired,
};


export default Header;
export { handleStickPlusIcon }
