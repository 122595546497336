import { dispatchError, getReq, postReq } from "../../../api";
import { setUserData } from "../../../redux/userSlice";
import { clearSuccessMessage, setSuccessMessage } from "../../../redux/errorSlice";
import { setLocalStorage } from "../../../utils";

const forgotPassword = ({ emailForgotPass, setIsLoading, setOpenModal }) => async (dispatch) => {
    setIsLoading(true);

    try {
        const res = await getReq(`/api/auth/resetPassword/${emailForgotPass}`, {}, null)

        if (res.status === 200) {
            dispatch(setSuccessMessage("Login details sent!"));
            setTimeout(() => {
              dispatch(clearSuccessMessage());
            }, 5000);
            setOpenModal(false);
            setIsLoading(false);
        } else {
            setIsLoading(false)
            dispatchError(res.data)
        }
    } catch (err) {
        setIsLoading(false)
        dispatchError(err)
    }
}

const login = ({ emailLogin, passwordLogin, rememberMe, setIsLoading }) => async (dispatch) => {
    setIsLoading(true)

    try {
        const res = await postReq("/api/auth/login", { email: emailLogin, password: passwordLogin })

        if (res.status === 200) {
            if (rememberMe) {
                setLocalStorage("chronowellApp", res.data);
              }
              dispatch(setUserData(res.data));
              setIsLoading(false);
        } else {
            setIsLoading(false)
            dispatchError(res.data)
        }
    } catch (err) {
        setIsLoading(false)
        dispatchError(err)
    }
}

const register = ({ emailSignup, passwordSignup, setIsLoading, username }) => async (dispatch) => {
    setIsLoading(true)

    try {
        const res = await postReq("/api/auth/register", {
            admin: 0,
            email: emailSignup,
            name: username,
            password: passwordSignup,
        })

        if (res.status === 200) {
            dispatch(setUserData(res.data));
            setIsLoading(false);
        } else {
            setIsLoading(false)
            dispatchError(res.data)
        }
    } catch (err) {
        setIsLoading(false)
        dispatchError(err)
    }
}

export {
    forgotPassword,
    login,
    register
}