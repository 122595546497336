/* eslint-disable no-bitwise */

import { MONTHS_PER_YEAR } from "./constants";
const moment = require("moment");

export const lumaTest = (hex) => {
  if (hex === undefined) {
    return "#000";
  }

  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma < 100) {
    return "#fff"; // pick a different colour
  } else {
    return "#000";
  }
};

export const fill = (n) => {
  const arr = [];
  for (let i = 0; i < n; i += 1) {
    arr.push(i);
  }
  return arr;
};

const COLORS = [
  "FF005D",
  "0085B6",
  "0BB4C1",
  "00D49D",
  "FEDF03",
  "233D4D",
  "FE7F2D",
  "FCCA46",
  "A1C181",
  "579C87",
];

export const randomColor = () =>
  COLORS[Math.floor(Math.random() * COLORS.length)];

let color = -1;
export const nextColor = () => {
  color = (color + 1) % COLORS.length;
  return COLORS[color];
};

// let prevColor = null
// export const nextRandomColor = () => {
//   let c = randomColor()
//   while (c === prevColor) {
//     c = randomColor()
//   }
//   prevColor = c
//   return c
// }

// export const randomColor = () => {
//   const LETTERS = '0123456789ABCDEF'
//   let color = ''
//   for (let i = 0; i < 6; i += 1) {
//     color += LETTERS[Math.floor(Math.random() * 16)]
//   }
//   return color
// }

export const hexToRgb = (hex) => {
  const v = parseInt(hex, 16);
  const r = (v >> 16) & 255;
  const g = (v >> 8) & 255;
  const b = v & 255;
  return [r, g, b];
};

export const colourIsLight = (r, g, b) => {
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return a < 0.5;
};

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year;
  let m = monthsToAdd;
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR;
    y += 1;
  }
  return { year: y, month: m + 1 };
};

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd);
  return new Date(`${r.year}-${r.month}`);
};

export const getWeekOfMonth = (dateObj) => {
  const date = moment(dateObj);
  const weekInYear = date.isoWeek();
  const result = weekInYear - date.startOf("month").isoWeek();

  return result < 0 ? weekInYear : result;
};

export const setLocalStorage = (identifier, payload) => {
  localStorage.setItem(identifier, JSON.stringify(payload));
};

export const clearLocalStorage = (identifier) => {
  localStorage.removeItem(identifier);
};

export const getLocalStorage = (identifier) => {
  const contestantInfo = localStorage.getItem(identifier);
  if (!!contestantInfo) {
    return JSON.parse(contestantInfo);
  } else {
    return null;
  }
};

export const getLastDayOfQuarter = (date) => {
  //   var year = date.getFullYear();
  //   var quarterEndings = [
  //     [3, 31],
  //     [6, 30],
  //     [9, 30],
  //     [12, 31],
  //   ];

  //   var toDateObj = function (dates) {
  //     return new Date(year, dates[0] - 1, dates[1]);
  //   };

  //   var isBeforeEndDate = function (endDate) {
  //     return endDate >= date;
  //   };

  //   date.setHours(0, 0, 0, 0);

  //   console.log("getLastDayOfQuarter");
  //   console.log(quarterEndings.map(toDateObj).filter(isBeforeEndDate)[0]);

  //   return quarterEndings.map(toDateObj).filter(isBeforeEndDate)[0];

  // console.log(moment().endOf('quarter'))
  return moment().endOf('quarter')
};

export const getFirstDayOfQuarter = (date) => {
  //   var year = date.getFullYear();

  //   var quarterStarts = [
  //     [1, 1],
  //     [4, 1],
  //     [7, 1],
  //     [10, 1],
  //   ];

  //   var toDateObj = function (dates) {
  //     return new Date(year, dates[0] - 1, dates[1]);
  //   };

  //   date.setHours(0, 0, 0, 0);

  //   console.log("getFirstDayOfQuarter");
  //   console.log(quarterStarts.map(toDateObj)[0]);

  //   return quarterStarts.map(toDateObj)[0];
  // console.log(moment().startOf('quarter'))
  return moment().startOf('quarter')
};

export const setEndDateUtil = ({ cb, data, event, start }) => {
  // check for single day and make so dates pass validation
  const startValMs = data.value.getTime();
  const endValMs = start.getTime();

  let endVal = data.value;

  if (startValMs === endValMs) {
    endVal = new Date(endValMs + 3.4 * 3600 * 1000);
  }

  event !== undefined && cb(endVal)
  // event !== undefined && updateValues("end", endVal);
}